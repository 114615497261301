import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import InputMask from "react-input-mask";
import Select from "react-select";
export default function SendOfferStep4({
  step,
  changeStep,
  watchAllFields,
  register,
  errors,
  isValid,
  control,
  siteSettings,
  offer_row,
  trigger,
  member,
  member_information,
  handleIdentityVerification,
  setValue,
}) {
  return (
    <>
      <fieldset>
        <div className="medium_blk">
          <div className="form_row row">
            <div className="col-xs-6  ">
              <h6>First Name</h6>
              <div className="form_blk">
                <input
                  type="text"
                  name="fname"
                  id="fname"
                  className="txtBox"
                  defaultValue={member_information?.fname}
                  {...register("fname", {
                    pattern: {
                      value: /^[a-zA-Z][a-zA-Z ]*$/,
                      message: "Invalid value",
                    },
                    required: "Required",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="fname"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="col-xs-6  ">
              <h6>Last Name</h6>
              <div className="form_blk">
                <input
                  type="text"
                  name="lname"
                  id="lname"
                  className="txtBox"
                  defaultValue={member_information?.lname}
                  {...register("lname", {
                    pattern: {
                      value: /^[a-zA-Z][a-zA-Z ]*$/,
                      message: "Invalid value",
                    },
                    required: "Required",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="lname"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="col-xs-6  ">
              <h6>Date of Birth</h6>
              <div className="form_blk">
                {/* <Controller
                                    control={control}
                                    name="dob"
                                    {...register("dob", { required: "Required" })}
                                    render={({ field }) => (
                                        <DatePicker
                                            className="txtBox"
                                            placeholderText="_ _ / _ _ / _ _ _ _"
                                            onChange={(date) => field.onChange(date)}
                                            selected={field.value}
                                        />
                                    )}
                                /> */}
                <InputMask
                  mask="99-99-9999"
                  name="dob"
                  id="dob"
                  className="txtBox"
                  autoComplete="none"
                  {...register("dob", {
                    required: "Required",
                  })}
                  defaultValue={watchAllFields?.dob}
                />
                <ErrorMessage
                  errors={errors}
                  name="dob"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="col-xs-6  ">
              <h6>Social Security Number</h6>
              <div className="form_blk">
                {/* <input
                  type="text"
                  name="ssn"
                  id="ssn"
                  className="txtBox"
                  {...register("ssn", {
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a number",
                    },
                    required: "Required",
                    minLength: {
                      value: 9,
                      message: "Length must be 9",
                    },
                    maxLength: {
                      value: 9,
                      message: "Length must be 9",
                    },
                  })}
                /> */}
                <InputMask
                            mask="999999999"
                            name="ssn"
                            id="ssn"
                            className="txtBox"
                            autoComplete="none"
                            {...register("ssn", {
                              required: "Required",
                            })}
                          />
                <ErrorMessage
                  errors={errors}
                  name="ssn"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="col-xs-12  ">
              <h6>Address Line 1</h6>
              <div className="form_blk">
                <input
                  type="text"
                  name=""
                  id=""
                  className="txtBox"
                  {...register("address_line1", { required: "Required" })}
                  defaultValue={
                    member_information?.address_line1 &&
                      member_information?.address_line1 !== 0 &&
                      member_information?.address_line1 !== "0"
                      ? member_information?.address_line1
                      : ""
                  }
                />
                <ErrorMessage
                  errors={errors}
                  name="address_line1"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="col-xs-12  ">
              <h6>Address Line 2</h6>
              <div className="form_blk">
                <input
                  type="text"
                  name="address_line2"
                  id="address_line2"
                  className="txtBox"
                  {...register("address_line2")}
                  defaultValue={
                    member_information?.address_line2 !== null && member_information?.address_line2 !== '' && member_information?.address_line2 !== 'null' && member_information?.address_line2 !== undefined && member_information?.address_line2 !== '0'
                      ? member_information?.address_line2
                      : ""
                  }
                />
              </div>
            </div>
            <div className="col-xs-4  ">
              <h6>City</h6>
              <div className="form_blk">
                <input
                  type="text"
                  name="city"
                  id="city"
                  className="txtBox"
                  {...register("city", { required: "Required" })}
                  defaultValue={
                    member_information?.city !== null && member_information?.city !== undefined && member_information?.city !== '' && member_information?.city !== 'null' && member_information?.city !== '0' ? member_information?.city : ""
                  }
                />
                <ErrorMessage
                  errors={errors}
                  name="city"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="col-xs-4  ">
              <h6>State</h6>
              <div className="form_blk">
                <div className="form_blk selectTxtGrp">
                  {/* <select name="state" id="state" className="txtBox" {...register("state", { required: "Required" })}>
                                        <option value="">State</option>
                                        {
                                            siteSettings != undefined && siteSettings?.states?.map((state, index) => {
                                                return <>

                                                    <option value={state.value} key={index} selected={parseInt(member_information?.state) === state.value ? 'selected' : ""}>{state.label}</option>
                                                </>
                                            })
                                        }
                                    </select> */}
                  <Controller
                    {...register("state", { required: "Required" })}
                    name="state"
                    id="state"
                    className=""
                    control={control}
                    defaultValue={watchAllFields?.state}
                    render={({ field: { onChange, value, ref, name } }) => (
                      <>
                        <Select
                          options={siteSettings?.states}
                          onChange={(val) => {
                            onChange(val.value);
                            setValue("state_label", val.label);
                          }}
                          label="Select State"
                          defaultValue={siteSettings?.states.filter(function (
                            option
                          ) {
                            return option.value === value;
                          })}
                        />
                      </>
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="state"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-4  ">
              <h6>ZIP Code</h6>
              <div className="form_blk">
                <input
                  type="text"
                  name="zip_code"
                  id="zip_code"
                  className="txtBox"
                  {...register("zip_code", {
                    required: "Required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a number",
                    },
                    required: "Required",
                    minLength: {
                      value: 5,
                      message: "Length must be 5",
                    },
                    maxLength: {
                      value: 5,
                      message: "Length must be 5",
                    },
                  })}
                  defaultValue={
                    member_information?.zip_code !== undefined && member_information?.zip_code !== null && member_information?.zip_code !== 'null' && member_information?.zip_code !== '0' && member_information?.zip_code !== ''
                      ? member_information?.zip_code
                      : ""
                  }
                />
                <ErrorMessage
                  errors={errors}
                  name="zip_code"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="col-xs-12  ">
              <div className="lblBtn">
                <input
                  type="checkbox"
                  name="confirm"
                  id="confirm"
                  value=""
                  {...register("confirm", { required: "Required" })}
                />
                <label htmlFor="confirm">
                  I agree to TransUnion's <a href="?">Terms and Conditions</a>
                </label>
              </div>
              <ErrorMessage
                errors={errors}
                name="confirm"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="cmnBtn text-center offering_send_btns">
            <button
              type="button"
              className="webBtn backBtn"
              onClick={() => changeStep(step - 1)}
            >
              Previous
            </button>
            {
              !isValid ? (
                <button
                  type="button"
                  className="webBtn colorBtn nextBtn"
                  onClick={() => {
                    trigger();
                  }}
                >
                  Next
                </button>
              ) : (
                //to Check verification on next step every time when click next
                <button
                  type="button"
                  className="webBtn colorBtn nextBtn"
                  onClick={handleIdentityVerification}
                  disabled={!isValid}
                >
                  Next
                </button>
              )
              // <button type="button" className="webBtn colorBtn nextBtn" onClick={() => changeStep(step + 1)} disabled={!isValid}>Next</button>
            }
          </div>
        </div>
      </fieldset>
    </>
  );
}
