import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import BranchStep1 from "./branches/step1";
import BranchStep2 from "./branches/step2";
import BranchStep3 from "./branches/step3";

export default function AddBranchForm({
  changeStep,
  step,
  amenities,
  isFormProcessing,
  handleBranch,
  branch_row,
  states,
}) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    trigger,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const onSubmit = (data) => {
    let amenities = null;

    if (
      data?.amenities !== undefined &&
      data?.amenities !== null &&
      data?.amenities !== "" &&
      data?.amenities?.length > 0
    ) {
      amenities = data?.amenities;
    }
    let newData = { ...data, amenities: amenities };
    // ; return;
    handleBranch(newData);
  };
  useEffect(() => {
    setValue("address_line1", branch_row?.address_line1);
    setValue("address_line2", branch_row?.address_line2);
    setValue("city", branch_row?.city);
    setValue("state", branch_row?.state);
    setValue("zip_code", branch_row?.zip_code);
    setValue("property_type", branch_row?.property_type);
    setValue("house_style", branch_row?.house_style);
    setValue("amenities", branch_row?.amenities);
  }, [branch_row]);
  let property_options = [
    {
      label: "Single Family",
      value: "Single Family",
    },
    {
      label: "Townhouse",
      value: "Townhouse",
    },
    {
      label: "Condo",
      value: "Condo",
    },
    {
      label: "Apartment",
      value: "Apartment",
    },
    {
      label: "Duplex",
      value: "Duplex",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  let house_style_options = [
    {
      label: "Craftsman",
      value: "Craftsman",
    },
    {
      label: "Ranch",
      value: "Ranch",
    },
    {
      label: "Tudor",
      value: "Tudor",
    },
    {
      label: "Victorian",
      value: "Victorian",
    },
    {
      label: "Colonial",
      value: "Colonial",
    },
    {
      label: "Federal",
      value: "Federal",
    },
    {
      label: "Italianate",
      value: "Italianate",
    },
  ];
  return (
    <>
      <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
        {step === 0 ? (
          <BranchStep1
            step={step}
            changeStep={changeStep}
            errors={errors}
            register={register}
            branch_row={branch_row}
            isValid={isValid}
            trigger={trigger}
            states={states}
            control={control}
            watchAllFields={watchAllFields}
          />
        ) : step === 1 ? (
          <BranchStep3
            step={step}
            changeStep={changeStep}
            errors={errors}
            register={register}
            branch_row={branch_row}
            isValid={isValid}
            trigger={trigger}
            watchAllFields={watchAllFields}
          />
        ) : step === 2 ? (
          <BranchStep2
            step={step}
            changeStep={changeStep}
            errors={errors}
            register={register}
            branch_row={branch_row}
            isFormProcessing={isFormProcessing}
            property_options={property_options}
            house_style_options={house_style_options}
            amenities={amenities}
            control={control}
            trigger={trigger}
            isValid={isValid}
            watchAllFields={watchAllFields}
            setValue={setValue}
          />
        ) : (
          ""
        )}
      </form>
    </>
  );
}
