import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PayPal from "../../../common/paypal";
import SaleListingStep1 from "../sale-listing-steps/step1";
import SaleListingStep2 from "../sale-listing-steps/step2";
import SaleListingStep3 from "../sale-listing-steps/step3";
import {
  convertSimpleDateUsingMoment,
  hasDatePassed,
  postData,
} from "../../../../helpers/api";
import Step5OpenHouse from "../sale-listing-steps/step5";
import moment from "moment";
import AddNewCreditCard from "../../dashboard-company/payments/add-new-credit-card";
import { ErrorMessage } from "@hookform/error-message";

export default function SaleListingForm({
  formStep,
  register,
  errors,
  changeStep,
  watchAllFields,
  isValid,
  control,
  choosePaymentMethod,
  paymentMethod,
  getPaypalObject,
  errorPaypalOrder,
  cancelPaypalOrder,
  checkoutError,
  handleCardDetailsChange,
  handleSubmit,
  setCheckoutError,
  stripePayment,
  property_slug,
  isFormProcessing,
  trigger,
  calculateListingFee,
  siteSettings,
  calculateListingProcessingFee,
  openHouses,
  setOpenHouses,
  mem_payment_methods,
}) {
  const [addNewCreditCard, setAddNewCreditCard] = useState(false);
  const handleAddNewHouse = () => {
    setOpenHouses({
      ...openHouses,
      openHouses: openHouses.openHouses.concat([
        { date: "", start_time: "", end_time: "" },
      ]),
    });
  };
  function handleRemoveHouse(idx, e) {
    e.preventDefault();
    setOpenHouses({
      ...openHouses,
      openHouses: openHouses.openHouses
        .slice(0, idx)
        .concat(
          openHouses.openHouses.slice(idx + 1, openHouses.openHouses.length)
        ),
    });
  }
  const isDateValid = (dateString) => {
    // // Assuming your date format is MM-DD-YYYY
    const enteredDate = moment(dateString).format("YYYY-MM-DD");
    return hasDatePassed(enteredDate);
  };
  const handleHouseChange = (idx, evt, type = null) => {
    const newHouses = openHouses.openHouses.map((open_house, sidx) => {
      if (idx !== sidx) return open_house;
      return { ...open_house, [type]: evt };
    });
    setOpenHouses({ ...openHouses, openHouses: newHouses });
  };

  const [isProcessing, setProcessingTo] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const chargePayment = async (
    clientSecret,
    paymentMethodReq,
    setup_id,
    paymentMethodSetup,
    customer_id
  ) => {
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodSetup,
      setup_future_usage: "off_session",
    });
    if (result.error) {
      setCheckoutError(result.error.message);
      setProcessingTo(false);
      return;
    } else if ((result.status = "succeeded")) {
      let data = watchAllFields;
      const formattedData = openHouses?.openHouses.map((item) => ({
        ...item,
        date: convertSimpleDateUsingMoment(item.date), // Format the date
      }));
      let newData = {
        ...data,
        payment_intent: result.paymentIntent.id,
        payment_method_id: paymentMethodReq.paymentMethod.id,
        customer_id: customer_id,
        property: property_slug,
        amount: calculateListingFee(),
        open_houses: JSON.stringify(formattedData),
      };
      stripePayment(newData);
      setProcessingTo(false);
    }
  };
  const onSubmit = async (data) => {
    // setProcessingTo(true);
    // if (!stripe || !elements) {
    //   // Stripe.js has not yet loaded.
    //   // Make sure to disable form submission until Stripe.js has loaded.
    //   return;
    // }
    const formattedData = openHouses?.openHouses.map((item) => ({
      ...item,
      date: convertSimpleDateUsingMoment(item.date), // Format the date
    }));
    let newData = {
      ...data,
      property: property_slug,
      amount: calculateListingFee(),
      open_houses: JSON.stringify(formattedData),
    };
    stripePayment(newData);
    setProcessingTo(false);

    // const cardElement = elements.getElement(CardElement);
    // try {
    //   const paymentMethodReq = await stripe.createPaymentMethod({
    //     type: "card",
    //     card: cardElement,
    //     // billing_details: billingDetails
    //   });
    //   if (paymentMethodReq.error) {
    //     setCheckoutError(paymentMethodReq.error.message);
    //     setProcessingTo(false);
    //     return;
    //   } else {
    //     var form_data = new FormData();
    //     for (var key in watchAllFields) {
    //       form_data.append(key, watchAllFields[key]);
    //     }
    //     form_data.append("payment_method", paymentMethodReq.paymentMethod.id);
    //     form_data.append("amount", calculateListingFee());
    //     await postData("api/create-payment-intent", form_data).then((data) => {
    //       let client_secret = data.arr.client_secret;
    //       let client_secret_setup = data.arr.setup_client_secret;
    //       if (data.status === 1) {
    //         let card_result = stripe.confirmCardSetup(client_secret_setup, {
    //           payment_method: {
    //             card: cardElement,
    //             // billing_details: billingDetails,
    //           },
    //         });

    //         if (card_result.error) {
    //           setCheckoutError(card_result.error.message);
    //           setProcessingTo(false);
    //           return;
    //         } else {
    //           let paymentMethod = "";
    //           let setup_intent_id = "";
    //           card_result.then((response) => {
    //             if (response.error) {
    //               setCheckoutError(response.error.message);
    //               setProcessingTo(false);
    //               return;
    //             } else {
    //               paymentMethod = response.setupIntent.payment_method;
    //               setup_intent_id = response.setupIntent.id;
    //               chargePayment(
    //                 client_secret,
    //                 paymentMethodReq,
    //                 setup_intent_id,
    //                 paymentMethod,
    //                 data.arr.customer
    //               );
    //             }
    //           });
    //         }
    //       }
    //     });
    //   }
    // } catch (err) {
    //   setCheckoutError(err.message);
    // }
    // let newData = { ...data, paymentMethod: paymentMethod, imageThumbnail: imageThumbnail };
    //
    // dispatch(postProperty(newData, property_id))
  };

  return (
    <>
      <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
        {formStep === 0 ? (
          <SaleListingStep1
            step={formStep}
            changeStep={changeStep}
            register={register}
            control={control}
            errors={errors}
            isValid={isValid}
            trigger={trigger}
          />
        ) : formStep === 1 ? (
          <Step5OpenHouse
            step={formStep}
            changeStep={changeStep}
            watchAllFields={watchAllFields}
            errors={errors}
            isValid={isValid}
            register={register}
            control={control}
            trigger={trigger}
            siteSettings={siteSettings}
            openHouses={openHouses}
            handleAddNewHouse={handleAddNewHouse}
            handleHouseChange={handleHouseChange}
            handleRemoveHouse={handleRemoveHouse}
            isDateValid={isDateValid}
          />
        ) : formStep === 2 ? (
          <SaleListingStep2
            step={formStep}
            changeStep={changeStep}
            register={register}
            control={control}
            watchAllFields={watchAllFields}
            errors={errors}
            isValid={isValid}
            trigger={trigger}
            openHouses={openHouses}
          />
        ) : formStep === 3 ? (
          <SaleListingStep3
            step={formStep}
            changeStep={changeStep}
            register={register}
            control={control}
            watchAllFields={watchAllFields}
            errors={errors}
            isValid={isValid}
            trigger={trigger}
            siteSettings={siteSettings}
            calculateListingProcessingFee={calculateListingProcessingFee}
          />
        ) : formStep === 4 ? (
          <fieldset>
            <div className="form_row row justify_center_flex chk">
              <div className="col-xs-9">
                <div className="creditCard">
                  <div className="flex flex-2 cardSec listCardSec">
                    <div className="inner">
                      <div className="text-center small_card_txt">
                        <p>
                          <small>
                            Please choose a stored card for payment.
                          </small>
                        </p>
                      </div>
                      <select
                        className="txtBox"
                        {...register("payment_cards", {
                          required: "Required",
                        })}
                      >
                        {mem_payment_methods?.length > 0 ? (
                          mem_payment_methods?.map(
                            (mem_payment_method, index) => {
                              if (index == 0) {
                                return (
                                  <option
                                    value={mem_payment_method?.encoded_id}
                                    key={index}
                                    selected
                                  >
                                    ************
                                    {mem_payment_method?.card_number}
                                  </option>
                                );
                              } else {
                                return (
                                  <option
                                    value={mem_payment_method?.encoded_id}
                                    key={index}
                                  >
                                    ************
                                    {mem_payment_method?.card_number}
                                  </option>
                                );
                              }
                            }
                          )
                        ) : (
                          <option value="">No card found!</option>
                        )}
                      </select>
                      <ErrorMessage
                        errors={errors}
                        name="payment_cards"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>
                    <div className=" bTn text-center addNewPaymentMethodBtn">
                      <a
                        href="#!"
                        className="webBtn smBtn"
                        onClick={() => setAddNewCreditCard(true)}
                      >
                        <i className="fa fa-plus"></i> Credit Card
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cmnBtn text-center">
              <button
                type="button"
                className="webBtn backBtn"
                onClick={() => changeStep(formStep - 1)}
              >
                Previous
              </button>

              {paymentMethod === "credit-card" ? (
                <button
                  type="submit"
                  className="webBtn colorBtn"
                  disabled={isProcessing || isFormProcessing ? "disabled" : ""}
                >
                  Submit
                  {isProcessing || isFormProcessing ? (
                    <i className="spinner"></i>
                  ) : (
                    ""
                  )}
                </button>
              ) : (
                ""
              )}
            </div>
          </fieldset>
        ) : (
          ""
        )}
      </form>
      {addNewCreditCard === true ? (
        <AddNewCreditCard
          setAddNewCreditCard={setAddNewCreditCard}
          siteSettings={siteSettings}
        />
      ) : (
        ""
      )}
    </>
  );
}
