import React from "react";
import Image from "../../common/image";
import { Link } from "react-router-dom";

export default function ServicesGrid({ data }) {
    return (
        <>
            <section className="service_one">
                <div className="top_left_service_top">
                    <img src="/images/bg-left.png" alt="" />
                </div>
                <div className="top_right_service_top">
                    <img src="/images/bg-right.png" alt="" />
                </div>
                <div className="bottom_left_service_top">
                    <img src="/images/bg-left.png" alt="" />
                </div>
                <div className="bottom_right_service_top">
                    <img src="/images/bg-right.png" alt="" />
                </div>
                <div className="contain">
                    {data.service?.map((val, index) => {
                        return (
                            <div className="flex" key={index}>
                                <div className="colL">
                                    <div className="image">
                                        <Image src={val.image} alt={val.heading} />
                                    </div>
                                </div>
                                <div className="colR">
                                    <div className="inner">
                                        <div className="sec_heading">
                                            <h2><em>{val.color_heading}</em> {val.heading}</h2>
                                        </div>
                                        {/* {val.text} */}
                                        <div dangerouslySetInnerHTML={{ __html: val.text }}></div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="start_arrow">
                    <Link to={data.start_link} className="start_btn">
                        <Image src={data.start_btn_img} alt="start" />
                    </Link>
                    <div className="arrow_img">
                        <img src="/images/arrow.png" alt="" />
                    </div>
                    <Link to={data.end_btn_link} className="webBtn">{data.end_btn_lbl}</Link>
                </div>
            </section>
        </>
    )
}
