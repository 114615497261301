import React from "react";
import { NavLink } from "react-router-dom";
const mem_permissions = localStorage.getItem("mem_permissions");
const super_admin = localStorage.getItem("super_admin");
export default function BranchLst({ branch, handleDelete }) {
  const deleteItem = (branch_id) => {
    handleDelete(branch_id);
  };
  return (
    <>
      <tr>
        <td className="hide_cell_hyp">
          <span className="mini-show-cell">Branch</span>
          {branch != undefined ? branch.name : ""}
        </td>
        <td>
          <span className="mini-show-cell">Address</span>
          {branch != undefined ? branch?.formatted_address : ""}
        </td>
        <td>
          <span className="mini-show-cell">Users</span>{branch?.employees ? branch?.employees : 0}
        </td>
        {mem_permissions === "admin" && parseInt(super_admin) === 1 ? (
          <td className="flex_action_user_dash hide_cell_hyp">
            <div className="action_drop _dropDown">
              <div className="_dropBtn action_dots">
                <img src="/images/dashboard/dots.png" alt="" />
              </div>
              <ul className="_dropCnt dropLst">
                <li>
                  <NavLink to={"/edit-branch/" + branch.id}>Edit</NavLink>
                </li>
                <li>
                  <NavLink
                    to="#!"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you wish to delete this item?"
                        )
                      )
                        deleteItem(branch.id);
                    }}
                    className=""
                  >
                    Delete
                  </NavLink>
                </li>
              </ul>
            </div>
          </td>
        ) : (
          ""
        )}
      </tr>
    </>
  );
}
