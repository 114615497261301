import React, { useState, useEffect } from "react";
import {
  format_amount,
  convertDateOnly,
  getPackageName,
  getPackagePrice,
  postData,
  isPackageUpgraded,
  doObjToFormData,
} from "../../../helpers/api";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PayPal from "../../common/paypal";
import SendOfferStep1 from "./step1";
import SendOfferStep2 from "./step2";
import SendOfferStep3 from "./step3";
import SendOfferStep4 from "./step4";
import SendOfferStep5 from "./step5";
import SendOfferStep6 from "./step6";
import SendOfferStep7 from "./step7";
import PropertyInfo from "./property-info";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
import AddNewCreditCard from "../dashboard-company/payments/add-new-credit-card";

export default function SendOfferForm({
  step,
  changeStep,
  property_row,
  user,
  tenants,
  handleAddTenant,
  handleTenantChange,
  handleRemoveTenant,
  siteSettings,
  handleOfferValidation,
  isFormProcessing,
  exam,
  examQuestions,
  setExamQuestions,
  handleExamChange,
  updateQuestions,
  verify,
  handleVerify,
  getPaypalObject,
  errorPaypalOrder,
  cancelPaypalOrder,
  paymentMethod,
  choosePaymentMethod,
  checkoutError,
  setCheckoutError,
  handleCardDetailsChange,
  stripePayment,
  slug,
  watchAllFields,
  register,
  handleSubmit,
  control,
  watch,
  errors,
  isValid,
  handleSetFormWatchData,
  tenantsVerified,
  handleVerifyTenants,
  tenantsVerifiedLoading,
  userCheckVerified,
  handleCheckVerified,
  verifiedUserHandle,
  offer_exist,
  offer_row,
  packageUpgrade,
  setPackageUpgrade,
  trigger,
  requestResponse,
  setManualVerify,
  manualVerify,
  listing_row,
  member,
  member_information,
  package_verified,
  package_upgrade,
  package_names,
  formWatchData,
  setValue,
  checkIdentityVerification,
  setCheckIdentityVerification,
  handleIdentityVerification,
  manualVerifyQuesAnsPost,
  setManualVerifyQuesAnsPost,
  site_package_cost,
  member_tu_verification_status,
  manualOfferTenant,
  calculateListingProcessingFee,
  offer_data,
  tenantLoading,
  mem_payment_methods,
}) {
  useEffect(() => {
    if (mem_payment_methods?.length > 0) {
      setValue("payment_cards", mem_payment_methods[0]?.encoded_id);
      trigger()
    }
  }, [mem_payment_methods]);
  const [addNewCreditCard, setAddNewCreditCard] = useState(false);
  const [offerTerm, setOfferTerm] = useState({
    pruce: 0,
    term: 0,
  });
  const [isProcessing, setProcessingTo] = useState(false);
  const [verificationExamQuestions, setVerificationExamQuestions] =
    useState(false);
  const [packageVerificationLoading, setPackageVerificationLoading] =
    useState(false);
  const [isVerified, setUserVerification] = useState(false);
  //Stripe
  const stripe = useStripe();
  const elements = useElements();

  const chargePayment = async (
    clientSecret,
    paymentMethodReq,
    setup_id,
    paymentMethodSetup,
    customer_id
  ) => {
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodSetup,
      setup_future_usage: "off_session",
    });
    if (result.error) {
      setCheckoutError(result.error.message);
      setProcessingTo(false);
      return;
    } else if ((result.status = "succeeded")) {
      let data = watchAllFields;
      let newData = "";
      if (
        isPackageUpgraded(package_names, property_row, watchAllFields) === true
      ) {
        newData = {
          ...data,
          payment_intent: result.paymentIntent.id,
          payment_method_id: paymentMethodReq.paymentMethod.id,
          customer_id: customer_id,
          property: slug,
        };
        stripePayment(newData, true);
      } else {
        newData = {
          ...data,
          payment_intent: result.paymentIntent.id,
          payment_method_id: paymentMethodReq.paymentMethod.id,
          customer_id: customer_id,
          property: slug,
        };
        stripePayment(newData);
      }

      setProcessingTo(false);
    }
  };
  useEffect(() => {}, [formWatchData]);
  //On Submit
  const onSubmit = async (data = false) => {
    setProcessingTo(true);
    let newData = watchAllFields;

    if (watchAllFields?.package === "N") {
      let newData = { ...data, property: slug, manual_verify: "false" };
      stripePayment(newData);
    } else {
      if (data !== false) {
        newData = {
          ...watchAllFields,
          screeningRequestId: data?.screeningRequestId,
          screeningRequestRenterId: data?.screeningRequestRenterId,
          package_upgrade_report: data?.package_upgrade_report,
          amount:
            parseFloat(
              getPackagePrice(watchAllFields.package, site_package_cost)
            ) + parseFloat(calculateListingProcessingFee()),
        };
      }
      if (
        userCheckVerified !== false &&
        userCheckVerified !== 0 &&
        packageUpgrade !== true &&
        packageUpgrade !== 1
      ) {
        //
        verifiedUserHandle(newData);
        setProcessingTo(false);
      }
      if (package_verified === true) {
        var verify_form_data = doObjToFormData(watchAllFields);
        await postData(
          "api/check-renter-verification-status/" +
            property_row?.slug +
            "/" +
            property_row?.listing_row?.id +
            "/" +
            property_row?.mem_type,
          verify_form_data
        ).then((res) => {
          setPackageVerificationLoading(false);
          if (res?.status === 1 && res?.user_verified === 1) {
            var examObj = {
              screeningRequestId: res?.screeningRequestId,
              screeningRequestRenterId: res?.screeningRequestRenterId,
              package_upgrade_report: true,
            };
            let frmData = {
              ...newData,
              no_upgrade: "true",
              screeningRequestId: res?.screeningRequestId,
              screeningRequestRenterId: res?.screeningRequestRenterId,
              package_upgrade_report: true,
            };
            verifiedUserHandle(frmData);
            setProcessingTo(false);
          } else if (res?.status === 0 && res?.user_verified === 0) {
            setProcessingTo(false);
            setUserVerification(true);
            toast.error(res?.msg, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(() => {
              changeStep(3);
            }, 2000);
          } else {
            setProcessingTo(false);
            setUserVerification(true);
            toast.error(res?.msg, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      } else if (
        isPackageUpgraded(package_names, property_row, watchAllFields) !==
          undefined &&
        isPackageUpgraded(package_names, property_row, watchAllFields) !==
          true &&
        isPackageUpgraded(package_names, property_row, watchAllFields) !== 1
      ) {
        let frmData = {
          ...newData,
          no_upgrade: "true",
          amount:
            parseFloat(
              getPackagePrice(watchAllFields.package, site_package_cost)
            ) + parseFloat(calculateListingProcessingFee()),
        };
        verifiedUserHandle(frmData);
        setProcessingTo(false);
      } else {
        // if (!stripe || !elements) {
        //   // Stripe.js has not yet loaded.
        //   // Make sure to disable form submission until Stripe.js has loaded.
        //   return;
        // }

        // const cardElement = elements.getElement(CardElement);
        // try {
        //   const paymentMethodReq = await stripe.createPaymentMethod({
        //     type: "card",
        //     card: cardElement,
        //     // billing_details: billingDetails
        //   });
        //   if (paymentMethodReq.error) {
        //     setCheckoutError(paymentMethodReq.error.message);
        //     setProcessingTo(false);
        //     return;
        //   } else {
        //     var form_data = new FormData();
        //     for (var key in watchAllFields) {
        //       form_data.append(key, watchAllFields[key]);
        //     }
        //     form_data.append(
        //       "payment_method",
        //       paymentMethodReq.paymentMethod.id
        //     );
        //     form_data.append(
        //       "amount",
        //       parseFloat(
        //         getPackagePrice(watchAllFields.package, site_package_cost)
        //       ) + parseFloat(calculateListingProcessingFee())
        //     );
        //     await postData("api/create-payment-intent", form_data).then(
        //       (data) => {
        //         // ;
        //         let client_secret = data.arr.client_secret;
        //         let client_secret_setup = data.arr.setup_client_secret;
        //         if (data.status === 1) {
        //           let card_result = stripe.confirmCardSetup(
        //             client_secret_setup,
        //             {
        //               payment_method: {
        //                 card: cardElement,
        //                 // billing_details: billingDetails,
        //               },
        //             }
        //           );

        //           if (card_result.error) {
        //             setCheckoutError(card_result.error.message);
        //             setProcessingTo(false);
        //             return;
        //           } else {
        //             let paymentMethod = "";
        //             let setup_intent_id = "";
        //             card_result.then((response) => {
        //               if (response.error) {
        //                 setCheckoutError(response.error.message);
        //                 setProcessingTo(false);
        //                 return;
        //               } else {
        //                 paymentMethod = response.setupIntent.payment_method;
        //                 setup_intent_id = response.setupIntent.id;
        //                 chargePayment(
        //                   client_secret,
        //                   paymentMethodReq,
        //                   setup_intent_id,
        //                   paymentMethod,
        //                   data.arr.customer
        //                 );
        //               }
        //             });
        //           }
        //         }
        //       }
        //     );
        //   }
        // } catch (err) {
        //   setCheckoutError(err.message);
        // }
        let data = watchAllFields;
        data = {
          ...data,
          amount:
            parseFloat(
              getPackagePrice(watchAllFields.package, site_package_cost)
            ) + parseFloat(calculateListingProcessingFee()),
        };
        let newData = "";
        if (
          isPackageUpgraded(package_names, property_row, watchAllFields) ===
          true
        ) {
          newData = {
            ...data,
            property: slug,
          };
          stripePayment(newData, true);
        } else {
          newData = {
            ...data,
            property: slug,
          };

          stripePayment(newData);
        }
      }
    }

    // let newData = { ...data, paymentMethod: paymentMethod, imageThumbnail: imageThumbnail };
    //
    // dispatch(postProperty(newData, property_id))
  };
  const check_renter_verification_status = async (
    before_submission_check = false
  ) => {
    setPackageVerificationLoading(true);
    var form_data = doObjToFormData(watchAllFields);
    // form_data.append('landlordId', tenants_arr);
    await postData(
      "api/check-renter-verification-status/" +
        property_row?.slug +
        "/" +
        property_row?.listing_row?.id +
        "/" +
        property_row?.mem_type,
      form_data
    ).then((res) => {
      setPackageVerificationLoading(false);
      if (res?.status === 1 && res?.user_verified === 1) {
        var examObj = {
          screeningRequestId: res?.screeningRequestId,
          screeningRequestRenterId: res?.screeningRequestRenterId,
          package_upgrade_report: true,
        };

        handleSetFormWatchData(examObj);
        if (before_submission_check === false) {
          changeStep(6);
        } else if (before_submission_check === true) {
          // onSubmit(examObj);
          changeStep(6);
        }
      } else if (res?.status === 0 && res?.user_verified === 0) {
        setUserVerification(true);
        toast.error(res?.msg, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          changeStep(3);
        }, 2000);
      } else {
        toast.error(res?.msg, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };
  useEffect(() => {
    if (property_row?.mem_type === "company") {
      if (property_row?.branch_flexible_lease === "No") {
        let term_split = watchAllFields?.term?.split("-");
        if (
          watchAllFields?.term !== undefined &&
          watchAllFields?.term !== null &&
          watchAllFields?.term !== ""
        ) {
          setOfferTerm({ price: term_split[0], term: term_split[1] });
        }
      } else {
        setOfferTerm({
          price: watchAllFields?.price,
          term: watchAllFields?.term,
        });
      }
    } else {
      setOfferTerm({
        price: watchAllFields?.price,
        term: watchAllFields?.term,
      });
    }
  }, [watchAllFields?.price, watchAllFields?.term]);
  return (
    <>
      <div className="flex divide_offer_flex send_offer_new_blk">
        <PropertyInfo
          property_row={property_row}
          user={user}
          branch_id={
            property_row?.mem_type === "company"
              ? property_row?.branch_row?.id
              : false
          }
        />
        <div className="inner_colR">
          <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
            {offer_exist === true || offer_exist === 1 ? (
              <>
                <div className="alert alert-success">
                  You have already created an offer for this property!
                </div>
                <div className="bTn text-center">
                  <NavLink to="/offers" className="webBtn colorBtn">
                    Go to Offers Page
                  </NavLink>
                </div>
              </>
            ) : member_tu_verification_status === "manual" &&
              listing_row?.screening !== "N" ? (
              <div className="alert alert-danger">
                At this time, you cannot send an offer on this property because
                your identity has not been verified from the offer you placed
                for {manualOfferTenant?.property_row?.formatted_address}. Please
                call (833) 458-6338 to complete your verification and reference
                screening request ID {manualOfferTenant?.screeningRequestId}.
                Once your identity has been verified, you can return to place
                this offer.
              </div>
            ) : parseInt(listing_row?.offer_deadline_passed) === 1 ? (
              <div className="alert alert-danger">
                This Listing is no longer accepting new offers.
              </div>
            ) : (
              <div className="cmn_bt_mrgn multip_step_form_offer">
                <div className="option_lbl">
                  <ul>
                    {offer_data !== undefined &&
                    offer_data !== null &&
                    offer_data !== "" ? (
                      ""
                    ) : (
                      <>
                        <li className={step === 0 ? "active" : ""}>
                          Tenant(s)
                        </li>
                        <li className={step === 1 ? "active" : ""}>Offering</li>
                      </>
                    )}
                    {package_verified !== true ? (
                      member_tu_verification_status !== "manual" ? (
                        <li className={step === 2 ? "active" : ""}>Package</li>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}

                    {(member_tu_verification_status !== "manual" &&
                      watchAllFields?.package !== "N" &&
                      member?.user_verified !== true) ||
                    isVerified === true ? (
                      <li className={step === 3 ? "active" : ""}>Identity</li>
                    ) : (
                      ""
                    )}
                    {(member_tu_verification_status !== "manual" &&
                      watchAllFields?.package !== "N" &&
                      member?.user_verified !== true) ||
                    isVerified === true ? (
                      <>
                        <li className={step === 4 ? "active" : ""}>
                          Questionnaire
                        </li>
                        <li className={step === 5 ? "active" : ""}>Status</li>
                      </>
                    ) : (
                      ""
                    )}

                    {member_tu_verification_status == "manual" &&
                    watchAllFields?.package !== "N" ? (
                      ""
                    ) : (
                      // isPackageUpgraded(package_names, property_row, watchAllFields) !== undefined && isPackageUpgraded(package_names, property_row, watchAllFields) === true ?

                      //     <li className={step === 6 ? "active" : ""}>Confirm & Pay</li>
                      //     :
                      //     isPackageUpgraded(package_names, property_row, watchAllFields) === undefined ?
                      //         <li className={step === 6 ? "active" : ""}>Confirm & Pay</li>
                      //         :
                      //         ""
                      <li className={step === 6 ? "active" : ""}>
                        Confirm & Pay
                      </li>
                    )}
                  </ul>
                </div>
                {step === 0 && tenantLoading == false ? (
                  <SendOfferStep1
                    step={step}
                    changeStep={changeStep}
                    tenants={tenants}
                    handleAddTenant={handleAddTenant}
                    handleTenantChange={handleTenantChange}
                    handleRemoveTenant={handleRemoveTenant}
                    handleVerifyTenants={handleVerifyTenants}
                    tenantsVerified={tenantsVerified}
                    tenantsVerifiedLoading={tenantsVerifiedLoading}
                    trigger={trigger}
                    check_renter_verification_status={
                      check_renter_verification_status
                    }
                    tenantLoading={tenantLoading}
                  />
                ) : step === 1 ? (
                  <SendOfferStep2
                    step={step}
                    changeStep={changeStep}
                    register={register}
                    errors={errors}
                    isValid={isValid}
                    control={control}
                    watchAllFields={watchAllFields}
                    trigger={trigger}
                    listing_row={listing_row}
                    property_row={property_row}
                    package_verified={package_verified}
                    isProcessing={isProcessing}
                    packageVerificationLoading={packageVerificationLoading}
                    package_names={package_names}
                    check_renter_verification_status={
                      check_renter_verification_status
                    }
                    member_tu_verification_status={
                      member_tu_verification_status
                    }
                  />
                ) : step === 2 ? (
                  <SendOfferStep3
                    step={step}
                    changeStep={changeStep}
                    register={register}
                    errors={errors}
                    isValid={isValid}
                    control={control}
                    property_row={property_row}
                    offer_row={offer_row}
                    trigger={trigger}
                    package_verified={package_verified}
                    package_upgrade={package_upgrade}
                    package_names={package_names}
                    watchAllFields={watchAllFields}
                    isProcessing={isProcessing}
                    isFormProcessing={isFormProcessing}
                    check_renter_verification_status={
                      check_renter_verification_status
                    }
                    packageVerificationLoading={packageVerificationLoading}
                    member={member}
                    isPackageUpgradedFlag={
                      member?.user_verified !== true || isVerified === true
                        ? true
                        : false
                    }
                    site_package_cost={site_package_cost}
                    member_tu_verification_status={
                      member_tu_verification_status
                    }
                    manualOfferTenant={manualOfferTenant}
                  />
                ) : step === 3 ? (
                  <SendOfferStep4
                    step={step}
                    changeStep={changeStep}
                    watchAllFields={watchAllFields}
                    register={register}
                    errors={errors}
                    isValid={isValid}
                    control={control}
                    siteSettings={siteSettings}
                    offer_row={offer_row}
                    trigger={trigger}
                    member={member}
                    member_information={member_information}
                    handleIdentityVerification={handleIdentityVerification}
                    setValue={setValue}
                  />
                ) : step === 4 ? (
                  <>
                    <SendOfferStep5
                      step={step}
                      changeStep={changeStep}
                      watchAllFields={watchAllFields}
                      handleOfferValidation={handleOfferValidation}
                      isFormProcessing={isFormProcessing}
                      exam={exam}
                      examQuestions={examQuestions}
                      setExamQuestions={setExamQuestions}
                      handleExamChange={handleExamChange}
                      updateQuestions={updateQuestions}
                      handleVerify={handleVerify}
                      verify={verify}
                      handleSetFormWatchData={handleSetFormWatchData}
                      handleCheckVerified={handleCheckVerified}
                      userCheckVerified={userCheckVerified}
                      packageUpgrade={packageUpgrade}
                      setPackageUpgrade={setPackageUpgrade}
                      trigger={trigger}
                      requestResponse={requestResponse}
                      setManualVerify={setManualVerify}
                      manualVerify={manualVerify}
                      verificationExamQuestions={verificationExamQuestions}
                      checkIdentityVerification={checkIdentityVerification}
                      setCheckIdentityVerification={
                        setCheckIdentityVerification
                      }
                      manualVerifyQuesAnsPost={manualVerifyQuesAnsPost}
                      setManualVerifyQuesAnsPost={setManualVerifyQuesAnsPost}
                      setValue={setValue}
                      control={control}
                    />
                  </>
                ) : step === 5 ? (
                  <SendOfferStep6
                    step={step}
                    changeStep={changeStep}
                    watchAllFields={watchAllFields}
                    tenants={tenants}
                    offer_row={offer_row}
                    trigger={trigger}
                    manualVerify={manualVerify}
                  />
                ) : step === 6 ? (
                  <fieldset>
                    <div className="medium_blk">
                      {tenants !== undefined &&
                      tenants !== null &&
                      tenants.tenants?.length > 0 &&
                      tenants.tenants[0]?.fname !== "" ? (
                        <h4 className="ofer_heading">Tenant(s):</h4>
                      ) : (
                        ""
                      )}

                      <ul className="flex offer_send_confirm full_wide_price_listing">
                        {tenants !== undefined &&
                        tenants !== null &&
                        tenants.tenants?.length > 0 &&
                        tenants.tenants[0]?.fname !== "" ? (
                          <>
                            {offer_row !== undefined &&
                            offer_row !== null &&
                            offer_row !== "" &&
                            offer_row?.id > 0 ? (
                              ""
                            ) : (
                              <>
                                <li>
                                  <strong>Tenant Name</strong>
                                  <em>
                                    {member?.mem_fname +
                                      " " +
                                      member?.mem_lname}
                                  </em>
                                </li>
                                <li>
                                  <strong>Tenant Email</strong>
                                  <em>{member?.mem_email}</em>
                                </li>
                              </>
                            )}
                            {tenants.tenants?.map((tenant, index) => {
                              return (
                                <>
                                  {tenant?.fname !== "" ||
                                  tenant?.lname !== "" ? (
                                    <>
                                      <li>
                                        <strong>Tenant Name</strong>
                                        <em>
                                          {tenant?.fname + " " + tenant?.lname}
                                        </em>
                                      </li>
                                      <li>
                                        <strong>Tenant Email</strong>
                                        <em>{tenant?.email}</em>
                                      </li>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          ""
                        )}
                      </ul>

                      <div className="gap_line_divide"></div>
                      <h4 className="ofer_heading">Offering</h4>
                      <ul className="flex offer_send_confirm full_wide_price_listing cell_full_wide_lst_cell">
                        <li>
                          <strong>Price</strong>
                          <em>
                            {format_amount(
                              offer_row !== undefined &&
                                offer_row !== null &&
                                offer_row !== ""
                                ? offer_row?.price
                                : offerTerm?.price
                            )}
                          </em>
                        </li>
                        <li>
                          <strong>Term</strong>
                          <em>
                            {offer_row !== undefined &&
                            offer_row !== null &&
                            offer_row !== ""
                              ? offer_row?.term
                              : offerTerm?.term}
                          </em>
                        </li>
                        <li>
                          <strong>Move-in Date</strong>
                          <em>
                            {convertDateOnly(
                              offer_row !== undefined &&
                                offer_row !== null &&
                                offer_row !== ""
                                ? offer_row?.move_in_date
                                : watchAllFields?.move_in_date
                            )}
                          </em>
                        </li>
                      </ul>

                      <div className="gap_line_divide"></div>
                      {member_tu_verification_status !== "manual" ? (
                        <>
                          <h4 className="ofer_heading">Package </h4>
                          <ul className="flex offer_send_confirm full_wide_price_listing">
                            <li>
                              <strong>Package</strong>
                              <em>{getPackageName(watchAllFields?.package)}</em>
                            </li>
                            {watchAllFields?.package !== "N" ? (
                              <li>
                                <strong>Price</strong>
                                <em>
                                  {package_verified === true
                                    ? "Waived"
                                    : (isPackageUpgraded(
                                        package_names,
                                        property_row,
                                        watchAllFields
                                      ) !== undefined &&
                                        isPackageUpgraded(
                                          package_names,
                                          property_row,
                                          watchAllFields
                                        ) !== false) ||
                                      isPackageUpgraded(
                                        package_names,
                                        property_row,
                                        watchAllFields
                                      ) === undefined
                                    ? format_amount(
                                        getPackagePrice(
                                          watchAllFields?.package,
                                          site_package_cost
                                        )
                                      )
                                    : "Waived"}
                                </em>
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="gap_line_divide"></div>
                      {listing_row?.screening === "N" &&
                      watchAllFields?.package === "N" ? (
                        ""
                      ) : package_verified === true ? (
                        <p>
                          Your identify has been verified. Please navigate to
                          the Offers page to view your screening reports.
                        </p>
                      ) : watchAllFields?.package !== "N" ? (
                        <>
                          <h4 className="ofer_heading">Status</h4>

                          {manualVerify !== false &&
                          manualVerify?.request_status ===
                            "ManualVerificationRequired" ? (
                            <p>
                              We are unable to verify your identity online.
                              Please call customer support at (833) 458-6338 for
                              assistance with completing your screening over the
                              phone. Please reference Screening Request ID
                              188790. Once your payment is processed and your
                              identity is confirmed, you can navigate to the
                              Offers page to view your screening reports.
                            </p>
                          ) : isPackageUpgraded(
                              package_names,
                              property_row,
                              watchAllFields
                            ) === undefined && verify === true ? (
                            <p>
                              Your identify has been verified. Once your payment
                              is processed, you can navigate to the Offers page
                              to view your screening reports.
                            </p>
                          ) : ((isPackageUpgraded(
                              package_names,
                              property_row,
                              watchAllFields
                            ) !== undefined &&
                              isPackageUpgraded(
                                package_names,
                                property_row,
                                watchAllFields
                              )) ||
                              isPackageUpgraded(
                                package_names,
                                property_row,
                                watchAllFields
                              ) === undefined) !== false &&
                            userCheckVerified === true ? (
                            <p>
                              Your identify has been verified. Once your payment
                              is processed, you can navigate to the Offers page
                              to view your screening reports.
                            </p>
                          ) : isPackageUpgraded(
                              package_names,
                              property_row,
                              watchAllFields
                            ) !== undefined &&
                            isPackageUpgraded(
                              package_names,
                              property_row,
                              watchAllFields
                            ) !== true &&
                            verify === false ? (
                            <p>
                              Your identify has been verified. Please navigate
                              to the Offers page to view your screening reports.
                            </p>
                          ) : isPackageUpgraded(
                              package_names,
                              property_row,
                              watchAllFields
                            ) !== undefined &&
                            isPackageUpgraded(
                              package_names,
                              property_row,
                              watchAllFields
                            ) === true &&
                            verify === false ? (
                            <p>
                              Your identify has been verified. Once your payment
                              is processed, you can navigate to the Offers page
                              to view your screening reports.
                            </p>
                          ) : (
                            <p>
                              With over 400,000 active listings, FindHouses has
                              the largest inventory of apartments in the United
                              States. Create leases, track payments, and handle
                              Service Requests for your rental properties.
                              Create leases, track payments, and handle Service
                              Requests for your rental properties.
                            </p>
                          )}
                        </>
                      ) : (
                        ""
                      )}

                      {watchAllFields?.package !== "N" &&
                      package_verified !== true &&
                      ((isPackageUpgraded(
                        package_names,
                        property_row,
                        watchAllFields
                      ) !== undefined &&
                        isPackageUpgraded(
                          package_names,
                          property_row,
                          watchAllFields
                        ) !== false) ||
                        isPackageUpgraded(
                          package_names,
                          property_row,
                          watchAllFields
                        ) === undefined) ? (
                        // (userCheckVerified !== true && userCheckVerified !== 1) || (packageUpgrade !== false && packageUpgrade !== 0) ?
                        <>
                          <div className="cmn_bt_mrgn">
                            <ul className="total_count_price_listing mini_offer_main_price">
                              <li>
                                <strong>Package Fee</strong>
                                <em>
                                  {format_amount(
                                    getPackagePrice(
                                      watchAllFields?.package,
                                      site_package_cost
                                    )
                                  )}
                                </em>
                              </li>
                              <li>
                                <strong>Promotion Code</strong>
                                <em></em>
                              </li>{" "}
                              <li>
                                <strong>Processing Fee</strong>
                                <em>
                                  {format_amount(
                                    parseFloat(calculateListingProcessingFee())
                                  )}
                                </em>
                              </li>
                              <li>
                                <strong>Total Due</strong>
                                <em>
                                  {format_amount(
                                    parseFloat(
                                      getPackagePrice(
                                        watchAllFields?.package,
                                        site_package_cost
                                      )
                                    ) +
                                      parseFloat(
                                        calculateListingProcessingFee()
                                      )
                                  )}
                                </em>
                              </li>
                            </ul>
                          </div>

                          <div className="form_row row justify_center_flex chk">
                            <div className="col-xs-9 width_full_9">
                              <div className="creditCard">
                                {/* <div className="flex flex-2 headCredit cardSecBar">
                                  <div className="inner">
                                    <div className="lblBtn text-left">
                                      <input
                                        type="radio"
                                        id="card1"
                                        tabindex="1"
                                        name="card"
                                        checked={
                                          paymentMethod === "credit-card"
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          choosePaymentMethod("credit-card")
                                        }
                                      />
                                      <label htmlFor="card1">Credit Card</label>
                                    </div>
                                  </div>
                                  <div className="inner">
                                    <ul className="text-right">
                                      <li>
                                        <img
                                          src="/images/dashboard/card1.svg"
                                          alt=""
                                        />
                                      </li>
                                      <li>
                                        <img
                                          src="/images/dashboard/card2.svg"
                                          alt=""
                                        />
                                      </li>
                                      <li>
                                        <img
                                          src="/images/dashboard/card3.svg"
                                          alt=""
                                        />
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                {paymentMethod === "credit-card" ? (
                                  <div className="flex flex-2 cardSec">
                                    <div className="inner">
                                      <CardElement
                                        options={{
                                          style: {
                                            base: {
                                              fontSize: "14px",
                                              color: "#424770",
                                              "::placeholder": {
                                                color: "#aab7c4",
                                              },
                                            },
                                            invalid: {
                                              color: "#9e2146",
                                            },
                                          },
                                        }}
                                        onChange={handleCardDetailsChange}
                                      />
                                      {checkoutError && (
                                        <div className="alert alert-danger">
                                          {checkoutError}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )} */}
                                {/* <div className="flex flex-2 headCredit paypalSecBar">
                                  <div className="inner">
                                    <div className="lblBtn text-left">
                                      <input
                                        type="radio"
                                        id="card2"
                                        tabindex="1"
                                        name="card"
                                        onChange={() =>
                                          choosePaymentMethod("paypal")
                                        }
                                        checked={
                                          paymentMethod === "paypal"
                                            ? true
                                            : false
                                        }
                                      />
                                      <label htmlFor="card2">Pay Pal</label>
                                    </div>
                                  </div>
                                  <div className="inner other_pay">
                                    <img
                                      src="/images/dashboard/paypal.svg"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                {paymentMethod === "paypal" ? (
                                  <>
                                    <div className="flex flex-2 cardSec">
                                      <div className="inner">
                                        <PayPal
                                          price={
                                            parseFloat(
                                              getPackagePrice(
                                                watchAllFields?.package,
                                                site_package_cost
                                              )
                                            ) +
                                            parseFloat(
                                              calculateListingProcessingFee()
                                            )
                                          }
                                          getPaypalObject={getPaypalObject}
                                          cancelPaypalOrder={cancelPaypalOrder}
                                          errorPaypalOrder={errorPaypalOrder}
                                          priceMonth={0}
                                          description="Offer Creation"
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )} */}
                                <div className="flex flex-2 cardSec listCardSec">
                                  <div className="inner">
                                    <select
                                      className="txtBox"
                                      {...register("payment_cards", {
                                        required: "Required",
                                      })}
                                    >
                                      {mem_payment_methods?.length > 0 ? (
                                        mem_payment_methods?.map(
                                          (mem_payment_method, index) => {
                                            if (index == 0) {
                                              return (
                                                <option
                                                  value={
                                                    mem_payment_method?.encoded_id
                                                  }
                                                  key={index}
                                                  selected
                                                >
                                                  ************
                                                  {
                                                    mem_payment_method?.card_number
                                                  }
                                                </option>
                                              );
                                            } else {
                                              return (
                                                <option
                                                  value={
                                                    mem_payment_method?.encoded_id
                                                  }
                                                  key={index}
                                                >
                                                  ************
                                                  {
                                                    mem_payment_method?.card_number
                                                  }
                                                </option>
                                              );
                                            }
                                          }
                                        )
                                      ) : (
                                        <option value="">No card found!</option>
                                      )}
                                    </select>
                                    <ErrorMessage
                                      errors={errors}
                                      name="payment_cards"
                                      render={({ message }) => (
                                        <p className="error">
                                          <i className="fi-warning"></i>{" "}
                                          {message}
                                        </p>
                                      )}
                                    />
                                  </div>
                                  <div className=" bTn text-center addNewPaymentMethodBtn">
                                    <a
                                      href="#!"
                                      className="webBtn smBtn"
                                      onClick={() => setAddNewCreditCard(true)}
                                    >
                                      <i className="fa fa-plus"></i> Credit Card
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="cmnBtn text-center offering_send_btns">
                        {member_tu_verification_status === "manual" ? (
                          offer_data !== undefined &&
                          offer_data !== null &&
                          offer_data !== "" &&
                          listing_row?.screening === "N" ? (
                            <NavLink to="/offers" className="webBtn backBtn">
                              Previous
                            </NavLink>
                          ) : (
                            <button
                              type="button"
                              className="webBtn backBtn"
                              onClick={() => changeStep(1)}
                            >
                              Previous
                            </button>
                          )
                        ) : offer_data !== undefined &&
                          offer_data !== null &&
                          offer_data !== "" &&
                          package_verified === true ? (
                          <NavLink to="/offers" className="webBtn backBtn">
                            Previous
                          </NavLink>
                        ) : watchAllFields?.package === "N" ? (
                          <button
                            type="button"
                            className="webBtn backBtn"
                            onClick={() => changeStep(2)}
                          >
                            Previous
                          </button>
                        ) : package_verified === true ||
                          package_verified === 1 ||
                          package_verified === "true" ? (
                          <button
                            type="button"
                            className="webBtn backBtn"
                            onClick={() => changeStep(1)}
                          >
                            Previous
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="webBtn backBtn"
                            onClick={() => changeStep(step - 1)}
                          >
                            Previous
                          </button>
                        )}

                        {paymentMethod === "credit-card" ? (
                          <button
                            type="submit"
                            className="webBtn colorBtn"
                            disabled={
                              isProcessing || isFormProcessing ? "disabled" : ""
                            }
                          >
                            Submit
                            {isProcessing || isFormProcessing ? (
                              <i className="spinner"></i>
                            ) : (
                              ""
                            )}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </fieldset>
                ) : (
                  ""
                )}
              </div>
            )}
          </form>
          {addNewCreditCard === true ? (
            <AddNewCreditCard
              setAddNewCreditCard={setAddNewCreditCard}
              siteSettings={siteSettings}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
