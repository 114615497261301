import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Controller } from "react-hook-form";
import Select from "react-select";
import AddLeasePopup from "../../popups/leases-popup";
import { useDispatch } from "react-redux";
import { emptyPropertyOffersArr } from "../../../../states/actions/leases";
import MaintenanceForm from "../maintenance_team/form";
import { sortArrayByKey } from "../../../../helpers/api";
export default function Breadcrumb({
  dashboard_reload_link = false,
  page_name,
  add_link,
  parent_name,
  parent_link,
  popup_show = false,
  popup_name = "",
  properties,
  rent_listing_count,
  sale_listing_count,
  anchor = false,
  leases_properties = false,
  branches,
  mem_type,
  lease_row = null,
  staff_properties = [],
  tenant_all_leases = 0,
  maintenance_requests = false,
  total_landlord_properties = 0,
  mem_permissions = "",
  lease_promo_code=null,
  lease_promo_description=''
}) {
  const dispatch = useDispatch();
  const [popup, setPopup] = useState({
    item: null,
    show: false,
  });
  const [rentListing, setRentListing] = useState(false);
  const [saleListing, setSaleListing] = useState(false);
  const [listingMsg, setListingMsg] = useState(null);
  const [listProperty, setLIstProperty] = useState(null);
  const showPopup = (item) => {
    setPopup({ ...popup, item: item, show: true });
    if (popup_name === "choose-lease-property") {
      dispatch(emptyPropertyOffersArr());
    }

    // alert(!popup);
  };
  const changeProperty = (e) => {
    let property = e;
    setRentListing(rent_listing_count?.includes(property?.value));
    setSaleListing(sale_listing_count?.includes(property?.value));
    setLIstProperty(property?.slug);
    // alert(!popup);
  };
  const closePopup = () => {
    setPopup({ ...popup, item: null, show: false });
  };
  return (
    <>
      <div className="dash_heading_sec">
        <div className="main_page_heading">
          {dashboard_reload_link ? (
            <a href="/dashboard">Dashboard</a>
          ) : (
            <NavLink to="/dashboard">Dashboard</NavLink>
          )}
          <span>/</span>
          {parent_name && parent_name != "" ? (
            <>
              <NavLink to={parent_link}>{parent_name}</NavLink>
              <span>/</span>
            </>
          ) : (
            ""
          )}

          <h2>{page_name}</h2>
        </div>
        {
          lease_promo_code ?
          <div class="promotion_breadcrumb">
            <img src="/images/promotion.png" />
            <label>
              Promotion Applied: <strong>{lease_promo_code}</strong>
            </label>
            <div className="hover_desc">
              {lease_promo_description}
            </div>
            </div>
          :
          ""
        }
        {add_link && add_link != "" ? (
          <div className="bTn">
            {anchor === true ? (
              <a href={add_link} className="webBtn mini_web_btn">
                <i className="fa fa-plus"></i>
              </a>
            ) : (
              <NavLink to={add_link} className="webBtn mini_web_btn">
                <i className="fa fa-plus"></i>
              </NavLink>
            )}
          </div>
        ) : mem_type === "company" && mem_permissions === "power" ? (
          ""
        ) : popup_show === true &&
          tenant_all_leases > 0 &&
          total_landlord_properties > 0 ? (
          <div className="bTn">
            <a
              href="javascript:void(0)"
              className="webBtn mini_web_btn"
              onClick={() => showPopup(popup_name)}
            >
              <i className="fa fa-plus"></i>
            </a>
          </div>
        ) : popup_show === true &&
          total_landlord_properties > 0 &&
          tenant_all_leases <= 0 &&
          maintenance_requests === true ? (
          <div className="bTn">
            <NavLink
              to="/add-service-request/landlord"
              className="webBtn mini_web_btn"
            >
              <i className="fa fa-plus"></i>
            </NavLink>
          </div>
        ) : popup_show === true &&
          total_landlord_properties <= 0 &&
          tenant_all_leases > 0 &&
          maintenance_requests === true ? (
          <div className="bTn">
            <NavLink
              to="/add-service-request/tenant"
              className="webBtn mini_web_btn"
            >
              <i className="fa fa-plus"></i>
            </NavLink>
          </div>
        ) : popup_show === true ? (
          <div className="bTn">
            <a
              href="javascript:void(0)"
              className="webBtn mini_web_btn"
              onClick={() => showPopup(popup_name)}
            >
              <i className="fa fa-plus"></i>
            </a>
          </div>
        ) : (
          ""
        )}
        {lease_row?.id > 0 &&
        lease_row?.promo_code !== "" &&
        lease_row?.promo_code !== null &&
        lease_row?.promo_code !== undefined &&
        lease_row?.promo_code === "yes" ? (
          <div className="promotion_breadcrumb">
            <img src="/images/promotion.png" />
            <label>
              Promotion Applied:{" "}
              <strong>{lease_row?.promotion_row?.promo_code}</strong>
            </label>
            <div className="hover_desc">
              {lease_row?.promotion_row?.description}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {popup_name === "choose-lease-property" && popup.item === popup_name ? (
        <AddLeasePopup
          closePopup={closePopup}
          leases_properties={sortArrayByKey(leases_properties)}
          mem_type={mem_type}
          branches={branches}
        />
      ) : (
        ""
      )}
      {popup_name === "create-new-listing" && popup.item === popup_name ? (
        <section className="popup small_popup" data-popup="create-new-listing">
          <div className="tableDv">
            <div className="tableCell">
              {properties !== undefined && properties?.length > 0 ? (
                <div className="_inner">
                  <div className="crosBtn" onClick={() => closePopup()}></div>
                  <h4>Create listing for:</h4>
                  <form action="" method="">
                    <div className="txtGrp popup_select_property">
                      <Select
                        options={properties}
                        onChange={(val) => {
                          changeProperty(val);
                        }}
                        label="Select Property"
                      />
                    </div>
                  </form>
                  {listProperty !== null && listProperty !== undefined ? (
                    <div className="bTn justify_btn_center">
                      {saleListing === false ? (
                        <NavLink
                          to={"/sale-listing/" + listProperty}
                          className="webBtn"
                        >
                          Sale
                        </NavLink>
                      ) : (
                        <NavLink
                          to="#!"
                          className="webBtn"
                          onClick={() =>
                            setListingMsg(
                              "An active sale listing for this property already exists."
                            )
                          }
                        >
                          Sale
                        </NavLink>
                      )}
                      {rentListing === false ? (
                        <NavLink
                          to={"/rent-listing/" + listProperty}
                          className="webBtn colorBtn"
                        >
                          Rent
                        </NavLink>
                      ) : (
                        <NavLink
                          to="#!"
                          className="webBtn colorBtn"
                          onClick={() =>
                            setListingMsg(
                              "An active rental listing for this property already exists."
                            )
                          }
                        >
                          Rent
                        </NavLink>
                      )}
                      {listingMsg !== null &&
                      listingMsg !== "" &&
                      listingMsg !== undefined ? (
                        <div className="alert alert-info">{listingMsg}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="_inner">
                  <div className="crosBtn" onClick={() => closePopup()}></div>
                  <h4>
                    You need to add properties in order to add new listings!
                  </h4>
                </div>
              )}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      {popup_name === "create-new-maintenance-request" &&
      popup.item === popup_name ? (
        <section className="popup" data-popup="create-new-maintenance-request">
          <div className="tableDv">
            <div className="tableCell">
              <div className="_inner">
                <div className="crosBtn" onClick={() => closePopup()}></div>
                <h4>
                  Are you creating a service request for a property you own or
                  lease?
                </h4>
                <div className="bTn justify_btn_center">
                  <NavLink
                    to={"/add-service-request/landlord"}
                    className="webBtn"
                  >
                    Own
                  </NavLink>
                  <NavLink
                    to={"/add-service-request/tenant"}
                    className="webBtn colorBtn"
                  >
                    Lease
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      {popup_name === "create-new-staff" && popup.item === popup_name ? (
        <section className="popup" data-popup="create-new-staff">
          <div className="tableDv">
            <div className="tableCell">
              <div className="_inner">
                <div className="crosBtn" onClick={() => closePopup()}></div>
                <h3>Add New Staff</h3>
                <MaintenanceForm
                  properties={sortArrayByKey(staff_properties)}
                  staff_row={null}
                  person_id={null}
                />
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
}
