import React from "react";
import {
  format_amount,
  format_date,
  getServerImage,
} from "../../../../helpers/api";
import { NavLink } from "react-router-dom";
import Text from "../../../common/Text";

export default function MaintenanceRequestBlk({ items, type = "", mem_type,tenant_label_name }) {
  return (
    <>
      {items?.length > 0 ? (
        items?.map((item, index) => {
          return (
            <div className="lst" data-offer="received-request" key={index}>
              {type !== "" ? (
                <NavLink
                  to={"/view-service-request/" + item?.encoded_id + "/" + type}
                  className="overlay_link_dt"
                ></NavLink>
              ) : (
                <NavLink
                  to={
                    "/view-service-request/" +
                    item?.encoded_id +
                    "/" +
                    item?.type
                  }
                  className="overlay_link_dt"
                ></NavLink>
              )}

              <ul className="maintenance_property_blk">
                <li>
                  <div className="icoBlk">
                    <div className="icon border_solid wide_mobile_img">
                      <img
                        src={item?.property_thumbnail}
                        alt={item?.property_name}
                      />
                      <div className="status_price">
                        {item?.request_status === "staff_pending" ? (
                          <span className="badge yellow cell_lbl">Pending</span>
                        ) : item?.assigned_staff_members?.length > 0 &&
                          item?.status !== "completed" &&
                          item?.status !== "finished" ? (
                          <span className="badge blue cell_lbl">
                            In Progress
                          </span>
                        ) : item?.status === "pending" ? (
                          <span className="badge yellow cell_lbl">Pending</span>
                        ) : item?.status === "finished" &&
                          item?.total_cost > 0 ? (
                          <span className="badge orange cell_lbl">
                            Payment Requested
                          </span>
                        ) : item?.status === "finished" &&
                          item?.total_cost <= 0 ? (
                          <span className="badge blue cell_lbl">
                            In Progress
                          </span>
                        ) : item?.status === "assigned" ? (
                          <span className="badge blue cell_lbl">
                            In Progress
                          </span>
                        ) : (
                          <span className="badge green cell_lbl">
                            Completed
                          </span>
                        )}
                        {item?.request_mem_type === "member" &&
                        (item?.status === "finished" ||
                          item?.status === "completed") ? (
                          <span className="cell_lbl">
                            {parseInt(item?.is_staff_profile) === 1
                              ? format_amount(
                                  parseFloat(item?.total_cost) -
                                    parseFloat(item?.processing_fee)
                                )
                              : format_amount(item?.total_cost)}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="txt">
                      <h5>{item?.property_title}</h5>
                      <p>
                        <small>
                          <Text string={item?.property_address} />
                        </small>
                      </p>
                      <div className="status_price">
                        {item?.request_status === "staff_pending" ? (
                          <span className="badge yellow hide_cell_hyp">
                            Pending
                          </span>
                        ) : item?.assigned_staff_members?.length > 0 &&
                          item?.status !== "completed" &&
                          item?.status !== "finished" ? (
                          <span className="badge blue hide_cell_hyp">
                            In Progress
                          </span>
                        ) : item?.status === "pending" ? (
                          <span className="badge yellow hide_cell_hyp">
                            Pending
                          </span>
                        ) : item?.status === "finished" &&
                          item?.total_cost > 0 ? (
                          <span className="badge orange hide_cell_hyp">
                            Payment Requested
                          </span>
                        ) : item?.status === "finished" &&
                          item?.total_cost <= 0 ? (
                          <span className="badge blue hide_cell_hyp">
                            In Progress
                          </span>
                        ) : item?.status === "assigned" ? (
                          <span className="badge blue hide_cell_hyp">
                            In Progress
                          </span>
                        ) : (
                          <span className="badge green hide_cell_hyp">
                            Completed
                          </span>
                        )}
                        {item?.request_mem_type === "member" &&
                        (item?.status === "finished" ||
                          item?.status === "completed") ? (
                          <span>
                            {parseInt(item?.is_staff_profile) === 1
                              ? format_amount(
                                  parseFloat(item?.total_cost) -
                                    parseFloat(item?.processing_fee)
                                )
                              : format_amount(item?.total_cost)}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="hide_desktop_col">
                        <div className="sep_hypn">
                          <span>{item?.purpose}</span>
                        </div>
                        <div className="sep_hypn">
                          <span>{format_date(item?.created_at)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="hide_cell_hyp">
                  <h5 className="lease_heading_mini">Purpose</h5>
                  <div>{item?.purpose}</div>
                </li>
                <li className="hide_cell_hyp assigned_staff_z_index">
                  <ul className="tenants_dps_flex">
                    <h5 className="lease_heading_mini">{tenant_label_name}</h5>
                    <li>
                      <div className="inner">
                        <div className="mini_icon">
                          <img
                            src={getServerImage(
                              "storage/members/",
                              item?.tenant_image
                            )}
                            alt={item?.tenant_name}
                          />
                        </div>
                        <div className="bio_info">
                          <div className="name">
                            <h4>{item?.tenant_name}</h4>
                          </div>
                          {/* <div className="address">
                            <small>
                            {item?.property_address}
                            </small>
                          </div> */}
                        </div>
                      </div>
                      <div className="ico">
                        <img
                          src={getServerImage(
                            "storage/members/",
                            item?.tenant_image
                          )}
                          alt={item?.tenant_name}
                        />
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="hide_cell_hyp assigned_staff_z_index">
                  <ul className="tenants_dps_flex">
                    <h5 className="lease_heading_mini">Assigned to</h5>
                    {item?.assigned_staff_members?.length > 0
                      ? item?.assigned_staff_members?.map(
                          (assigned_staff_member, index) => {
                            return (
                              <li key={index}>
                                <div className="inner">
                                  <div className="mini_icon">
                                    <img
                                      src={assigned_staff_member?.thumb}
                                      alt={assigned_staff_member?.name}
                                    />
                                  </div>

                                  <div className="bio_info">
                                    <div className="name">
                                      <h4>{assigned_staff_member?.name}</h4>
                                    </div>
                                    {/* <div className="address">
                                      <small>
                                        4600 Mooretown Rd,
                                        <br /> Williamsburg VA 23185, USA
                                      </small>
                                    </div> */}
                                  </div>
                                </div>
                                <div className="ico">
                                  <img
                                    src={assigned_staff_member?.thumb}
                                    alt={assigned_staff_member?.name}
                                  />
                                </div>
                              </li>
                            );
                          }
                        )
                      : "Not assigned yet!"}
                  </ul>
                </li>
                <li className="hide_cell_hyp">
                  <h5 className="lease_heading_mini">Opened</h5>
                  <div>
                    {item?.created_at ? format_date(item?.created_at) : "--"}
                  </div>
                </li>

                <li className="hide_cell_hyp">
                  <h5 className="lease_heading_mini">Status</h5>
                  <span className="badge green">In Progress</span>
                </li>
              </ul>
            </div>
          );
        })
      ) : (
        <div className="alert alert-danger">No item(s) found yet!</div>
      )}
    </>
  );
}
