import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import Breadcrumb from "./sections/breadcrumb";
import MaintenanceRequestSort from "./maintenance-requests/sort";
import MaintenanceRequestBlk from "./maintenance-requests/blk";

import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import {
  fetchMaintenanceRequests,
  deleteRequest,
  getFilteredMaintenanceRequests,
} from "../../../states/actions/fetchMaintenanceRequests";
import { sortArrayByKey } from "../../../helpers/api";

export default function MaintenanceRequests({ mem_type }) {
  const [tabType, setTabType] = useState("received");
  const [propertyId, setPropertyID] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchMaintenanceRequests.content);
  const received_maintenance_arr = useSelector(
    (state) => state.fetchMaintenanceRequests.received_maintenance_arr
  );
  const maintenance_arr = useSelector(
    (state) => state.fetchMaintenanceRequests.maintenance_arr
  );
  const assigned_requests = useSelector(
    (state) => state.fetchMaintenanceRequests.assigned_requests_arr
  );
  const isFilterLoading = useSelector(
    (state) => state.fetchMaintenanceRequests.isFilterLoading
  );
  const isLoading = useSelector(
    (state) => state.fetchMaintenanceRequests.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchMaintenanceRequests.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchMaintenanceRequests());
  }, []);
  const handleDelete = (e, id) => {
    e.preventDefault();
    dispatch(deleteRequest(id));
  };
  useEffect(() => {
    if (
      data?.received_maintenance_arr?.length <= 0 &&
      data?.maintenance_arr?.length > 0
    ) {
      setTabType("my_requests");
    } else if (
      data?.received_maintenance_arr?.length <= 0 &&
      data?.maintenance_arr?.length <= 0 &&
      data?.assigned_requests_arr?.length > 0
    ) {
      setTabType("assigned");
    }
  }, [data?.maintenance_arr, data?.received_maintenance_arr]);
  const handlePropertyChange = (e) => {
    let property_id = e.target.value;
    setPropertyID(property_id);
    dispatch(
      getFilteredMaintenanceRequests({
        property_id: property_id,
        tab_type: tabType,
      })
    );
  };
  useDocumentTitle("Service Requests", "Loftus");
  if (isLoading) return <LoadingScreen />;
  const tenant_properties = data?.tenant_properties;
  const assigned_properties = data?.assigned_properties;
  const received_properties = data?.received_properties;
  const tenant_all_leases = data?.tenant_all_leases;
  const total_landlord_properties = data?.landlord_properties;
  console.log(data, tabType, propertyId);
  return (
    <>
      <ToastContainer />
      <main index className="main_dashboard">
        <section className="dashboard">
          <div className="contain">
            <Breadcrumb
              page_name="Service Requests"
              // add_link={mem_type === "member" ? "/add-maintenance-request" : ""}
              popup_show={true}
              popup_name="create-new-maintenance-request"
              tenant_all_leases={tenant_all_leases}
              maintenance_requests={true}
              total_landlord_properties={total_landlord_properties}
            />
            <div className="page_body">
              <div className="offer_dash_page leases_page_dash maintenance_requests_lsting">
                <MaintenanceRequestSort
                  tabType={tabType}
                  setTabType={setTabType}
                  my_requests={maintenance_arr?.length}
                  received={received_maintenance_arr?.length}
                  assigned={assigned_requests?.length}
                  received_properties={sortArrayByKey(received_properties)}
                  assigned_properties={sortArrayByKey(assigned_properties)}
                  tenant_properties={sortArrayByKey(tenant_properties)}
                  handlePropertyChange={handlePropertyChange}
                  propertyId={propertyId}
                />
                <div className="outer_list hide_last_child whole_lst_lnk">
                  <div className="tab-content">
                    {isFilterLoading ? (
                      <div className="loadingDisabled">
                        <i className="spinner"></i>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      id="received_request"
                      className="tab-pane fade active in a"
                    >
                      {tabType === "my_requests" ? (
                        <MaintenanceRequestBlk
                          items={maintenance_arr}
                          mem_type={mem_type}
                          tenant_label_name="Requester"
                        />
                      ) : tabType === "received" ? (
                        <MaintenanceRequestBlk
                          items={received_maintenance_arr}
                          mem_type={mem_type}
                          type="received"
                          tenant_label_name="Tenant"
                        />
                      ) : tabType === "assigned" ? (
                        <MaintenanceRequestBlk
                          items={assigned_requests}
                          mem_type={mem_type}
                          type="assigned"
                          tenant_label_name="Tenant"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
