import { NavLink } from "react-router-dom";
import { format_amount, getServerImage } from "../../../../helpers/api";
export default function PaymentBlk({ payment, setPaymentPopup, isLandlord }) {
  return (
    <>
      <tr data-popup="new-pay" className="relative">
        <td>
          <span className="mini-show-cell">Property</span>
          {payment?.title}
          {payment?.status === "accepted" &&
          parseInt(payment?.expired) !== 1 ? (
            <span className="badge cell_block_lbl_cell green">Active</span>
          ) : payment?.status === "rejected" ||
            parseInt(payment?.expired) === 1 ? (
            <span className="badge cell_block_lbl_cell red">Expired</span>
          ) : (
            ""
          )}
          {isLandlord ? (
            <NavLink
              to={"/landlord_payment/" + payment?.encoded_id}
              className="payment_fix_link"
            ></NavLink>
          ) : (
            <NavLink
              to={"/payment/" + payment?.encoded_id}
              className="payment_fix_link"
            ></NavLink>
          )}
        </td>
        <td className="hide_cell_hyp">
          <span className="mini-show-cell">Tenant</span>
          <ul className="tenants_dps_flex">
            {payment?.tenants?.map((tenant, index) => {
              return (
                <li key={index}>
                  <div className="inner">
                    <div className="mini_icon">
                      <img
                        src={getServerImage("storage/members/", tenant?.thumb)}
                        alt={tenant?.name}
                      />
                    </div>
                    <div className="bio_info">
                      <div className="name">
                        <h4>{tenant?.name}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="ico">
                    <img
                      src={getServerImage("storage/members/", tenant?.thumb)}
                      alt={tenant?.name}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </td>
        <td className="text-right">
          <span className="mini-show-cell">Balance</span>
          {format_amount(payment?.amount)}
        </td>
        <td className="text-center">
          <span className="mini-show-cell">Due Date</span>
          {payment?.rent_due_date}
        </td>
        {/* <td>
                    <a href="javascript:void(0)" className="webBtn smBtn" onClick={() => (setPaymentPopup({ item: payment, show: true }))}>Pay</a>
                </td> */}
      </tr>
    </>
  );
}
