import React, { useState, useEffect } from "react";
import {
  datepickerDateGet,
  getBranchProperties,
  getExistingListing,
} from "../../../../helpers/api";
import ListingStep1 from "./listings/step1";
import ListingStep2 from "./listings/step2";
import ListingStep3 from "./listings/step3";
import { useForm } from "react-hook-form";

export default function AddListingForm({
  changeStep,
  step,
  listing_row,
  branches,
  handleListing,
  handleAddNewPrice,
  prices,
  handleRemovePrice,
  handlePriceChange,
  isFormProcessing,
  properties_arr,
}) {
  const {
    register,
    setValue,
    handleSubmit,
    trigger,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  useEffect(() => {
    if (
      listing_row !== null &&
      listing_row !== undefined &&
      listing_row !== ""
    ) {
      setValue("branch", listing_row?.branch);
      setValue("property", listing_row?.property);
      setValue(
        "available",
        listing_row?.available !== null &&
          listing_row?.available !== undefined &&
          listing_row?.available !== ""
          ? datepickerDateGet(listing_row?.available)
          : new Date()
      );
    }
  }, [listing_row]);
  const onSubmit = (data) => {
    let newData = "";
    if (
      prices?.prices !== null &&
      prices?.prices !== undefined &&
      prices?.prices !== "" &&
      prices?.prices?.length > 0
    ) {
      newData = { ...data, listing_type: listing.type, prices: prices.prices };
    } else {
      newData = { ...data, listing_type: listing.type, prices: null };
    }

    // ;
    handleListing(newData);
  };
  useEffect(() => {
    if (
      listing_row !== undefined &&
      listing_row !== null &&
      listing_row !== ""
    ) {
      changeStep(1);
    }
  }, [listing_row]);
  const [listing, setListing] = useState({
    type: "single",
  });
  const handleTypeChange = (type) => {
    setListing({ ...listing, type: type });
  };
  const [listingExist, setListingExist] = useState({
    loading: false,
    status: 0,
  });
  const [properties, setProperty] = useState(null);
  useEffect(() => {
    if (
      properties_arr !== undefined &&
      properties_arr !== null &&
      properties_arr?.length > 0
    ) {
      setProperty(properties_arr);
    }
  }, [properties]);
  async function handleBranchChange(value) {
    let properties = await getBranchProperties(value);
    setProperty(properties.properties);
  }
  async function handleListingExist() {
    setListingExist({ ...listingExist, loading: true });
    let listing_exist = await getExistingListing(watchAllFields);

    setListingExist({
      ...listingExist,
      loading: false,
      status: listing_exist?.status,
      msg: listing_exist?.msg,
    });
    if (listing_exist?.status === 1) {
      changeStep(step + 1);
    }
  }
  console.log(watchAllFields);

  return (
    <>
      <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
        {step === 0 ? (
          <ListingStep1
            step={step}
            changeStep={changeStep}
            listing_type={listing.type}
            handleTypeChange={handleTypeChange}
          />
        ) : step === 1 ? (
          <ListingStep2
            step={step}
            changeStep={changeStep}
            branches={branches}
            listing_row={listing_row}
            handleBranchChange={handleBranchChange}
            properties={properties}
            register={register}
            control={control}
            isValid={isValid}
            errors={errors}
            handleListingExist={handleListingExist}
            listingExist={listingExist}
            trigger={trigger}
            setValue={setValue}
            watchAllFields={watchAllFields}
          />
        ) : step === 2 ? (
          <ListingStep3
            step={step}
            changeStep={changeStep}
            register={register}
            control={control}
            isValid={isValid}
            errors={errors}
            prices={prices}
            handleAddNewPrice={handleAddNewPrice}
            handleRemovePrice={handleRemovePrice}
            handlePriceChange={handlePriceChange}
            isFormProcessing={isFormProcessing}
          />
        ) : (
          ""
        )}
      </form>
    </>
  );
}
