import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";

import { useLocation } from "react-router-dom";
import PropertyGallery from "./propertyGallery";
import { NavLink } from "react-router-dom";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import { doObjToFormData, postData, timeAgoInNewYork } from "../../helpers/api";
import http from "../../helpers/http";
const authToken = localStorage.getItem("authToken");

export default function PropertyBlk({
  data,
  map_thumb = false,
  className = "col",
  isExplore = false,
  branch_id = false,
  from_favorites_page = false,
  listing_expired = false,
  hide_time=false
}) {
  const parentRefs = useRef([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const commercialSearchParams = `${searchParams.toString()}`;
  const [isFavorite, setIsFavorite] = useState(0);
  useEffect(() => {
    if (data?.user_type === "company") {
      setIsFavorite(parseInt(data?.is_favorite));
    } else {
      setIsFavorite(parseInt(data?.is_favorite));
    }
  }, [data]);
  const removeFavorite = async (event, index, listing_id) => {
    const parentElement = parentRefs.current[index];

    if (authToken === undefined || authToken === null || authToken === "") {
      toast.error("You need to login to add this product in favorites!");
      return;
    }
    let frmData = {
      listing_id: listing_id,
      mem_type: data?.user_type,
    };
    await postData("api/add-property-to-favorite", frmData).then((res) => {
      if (res?.status === 1) {
        if (from_favorites_page) {
          if (parentElement) {
            parentElement.remove();
          }
        }
        setIsFavorite(parseInt(res?.is_favorite));
        // toast.success(res?.msg);
      } else {
        toast.error(res?.msg);
      }
    });
  };
  return (
    <>
      <div
        className={className}
        key={data?.id}
        ref={(el) => (parentRefs.current[data?.id] = el)}
      >
        <div className="inner">
          {isExplore === false && from_favorites_page === false ? (
            data?.listing_type && data?.user_type ? (
              <div
                className={
                  data?.listing_type === "Rent" || data?.listing_type === "rent"
                    ? "color_lbl for_rent"
                    : "color_lbl for_sale"
                }
              >
                {data?.user_type === "company" ? (
                  <p>Multifamily</p>
                ) : (
                  <p>{data?.listing_type}</p>
                )}
              </div>
            ) : (
              ""
            )
          ) : (
            <>
              {data?.total_offers !== null &&
              data?.total_offers !== undefined &&
              data?.total_offers !== "" &&
              data?.total_offers > 0 ? (
                <div className="color_lbl for_offers">
                  {data?.total_offers > 1
                    ? data?.total_offers + " Offers"
                    : data?.total_offers + " Offer"}
                </div>
              ) : (
                ""
              )}
            </>
          )}
          {data?.user_type === "member" ? (
            <NavLink
              to={"/property/" + data?.slug + "/" + data?.listing_id}
              className="big_link"
              target="_blank"
            ></NavLink>
          ) : data?.user_type === "company" ? (
            <a
              href={
                commercialSearchParams !== ""
                  ? "/commercial-property/" +
                    data?.slug +
                    "?" +
                    commercialSearchParams
                  : "/commercial-property/" + data?.slug
              }
              className="big_link"
              target="_blank"
            ></a>
          ) : (
            ""
          )}

          <div
            className="fav_icon"
            onClick={(e) =>
              removeFavorite(
                e,
                data?.id,
                branch_id !== false ? branch_id : data?.listing_id
              )
            }
          >
            <span>
              <i className={isFavorite ? "fa fa-heart" : "fi-heart"}></i>
            </span>
          </div>

          {map_thumb === true ? (
            <div className="image_map">
              <img src={data?.image} alt={data?.title} />
            </div>
          ) : (
            <div className="image_slider">
              <PropertyGallery
                data={data?.images}
                thumbnail={data?.image}
                title={data?.title}
              />
              {data?.user_type === "member" &&
              data?.open_text !== "" &&
              data?.open_text !== undefined &&
              data?.open_text !== null ? (
                <div className="color_lbl for_hours">{data?.open_text}</div>
              ) : (
                ""
              )}
            </div>
          )}

          <div className="cntnt">
            <h3 dangerouslySetInnerHTML={{ __html: data?.title }} />

            {data?.user_type === "company" ? (
              <ul className="flex">
                <li>
                  {parseInt(data?.branch_studios) > 0 &&
                  parseInt(data?.beds) > 0 ? (
                    <span>
                      {parseInt(data?.branch_studios) > 0 ? "Studio" : ""}-
                      {parseInt(data?.beds) > 0 ? data?.beds + " Bd" : ""}
                    </span>
                  ) : parseInt(data?.branch_studios) > 0 &&
                    parseInt(data?.beds) <= 0 ? (
                    <span>
                      {parseInt(data?.branch_studios) > 0 ? "Studio" : ""}
                    </span>
                  ) : parseInt(data?.branch_studios) <= 0 ? (
                    <span>
                      {parseInt(data?.min_beds) !== parseInt(data?.beds)
                        ? data?.min_beds + "-" + data?.beds + " Bd"
                        : data?.beds + " Bd"}
                    </span>
                  ) : parseInt(data?.beds) <= 0 ? (
                    <span>
                      {parseInt(data?.beds) > 0 ? data?.beds + " Bd" : ""}
                    </span>
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  <span>{data?.baths > 0 ? data?.baths + " Ba" : "0 Ba"} </span>
                </li>
                <li>
                  <span>{data?.sf}</span>
                </li>
              </ul>
            ) : (
              <ul className="flex">
                <li>
                  <span>
                    {data?.studio === "yes" || data?.studio === "Yes"
                      ? "Studio"
                      : data?.beds + " Bed"}
                  </span>
                </li>
                {data?.baths ? (
                  <li>
                    <span>{data?.baths + " Ba"} </span>
                  </li>
                ) : (
                  <li>
                    <span>{"0 Ba"} </span>
                  </li>
                )}
                <li>
                  <span>{data?.sf}</span>
                </li>
              </ul>
            )}

            <ul className="flex">
              <li>
                <span dangerouslySetInnerHTML={{ __html: data?.address }} />
              </li>
            </ul>

            <div className="flex price_flex">
              {data?.promotion_row?.id > 0 ? (
                <div className="price promotion_price">
                  <span>{data?.price}</span>{" "}
                  <img src="/images/promotion.png" alt=" " />
                </div>
              ) : (
                <div className="price promotion_price">
                  <span>{data?.price}</span>{" "}
                </div>
              )}
              <p>
                {listing_expired !== true ? (
                  hide_time!==true ? 
                  data?.created_time ? (
                    timeAgoInNewYork(data?.created_time)
                  ) : (
                    data?.time
                  )
                  :
                  ""
                ) : (
                  <span className="expired_label">Expired</span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
