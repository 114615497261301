import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { datepickerDateGet } from "../../../../../helpers/api";
export default function ListingStep2({
  changeStep,
  step,
  branches,
  listing_row,
  handleBranchChange,
  properties,
  register,
  errors,
  control,
  isValid,
  handleListingExist,
  listingExist,
  trigger,
  setValue,
  watchAllFields
}) {
  
  
  
  
  return (
    <>
      <fieldset>
        <div className="form_row row flex">
          <div className="col-xs-12">
            <h6>Select Branch</h6>
            <div className="form_blk">
              <Controller
                {...register("branch", { required: "Required" })}
                name="branch"
                control={control}
                defaultValue={
                  listing_row != undefined &&
                  listing_row != null &&
                  listing_row !== ""
                    ? listing_row.branch
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={branches}
                      onChange={(val) => {
                        onChange(val.value);
                        handleBranchChange(val.value);
                      }}
                      isDisabled={
                        listing_row != undefined &&
                        listing_row != null &&
                        listing_row !== ""
                          ? true
                          : false
                      }
                      label="Select State"
                      defaultValue={branches.filter(function (option) {
                        return option.value === value;
                      })}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="branch"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-12">
            <h6>Select Property</h6>
            <div className="form_blk">
              <select
                name="property"
                id="property"
                className="txtBox"
                {...register("property", { required: "Required" })}
                readOnly={
                  listing_row !== null &&
                  listing_row !== undefined &&
                  listing_row?.id > 0
                    ? true
                    : false
                }
                disabled={
                  listing_row !== null &&
                  listing_row !== undefined &&
                  listing_row?.id > 0
                    ? true
                    : false
                }
              >
                {properties != null && properties.length > 0 ? (
                  <>
                    <option value="">Select Properties for Branch</option>
                    {properties?.map((property, index) => {
                      return (
                        <>
                          <option
                            value={property.value}
                            key={index}
                            selected={
                              listing_row?.property === property.value
                                ? true
                                : false
                            }
                          >
                            {property.label}
                          </option>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <option value="">No properties found</option>
                )}
              </select>
              <ErrorMessage
                errors={errors}
                name="property"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-12">
            <h6>Available</h6>
            <div className="form_blk">
              <Controller
                control={control}
                name="available"
                defaultValue={
                  listing_row !== null
                    ? datepickerDateGet(listing_row?.available)
                    : ""
                }
                {...register("available", { required: "Required" })}
                render={({ field }) => (
                  <DatePicker
                    className="txtBox"
                    placeholderText="_ _ / _ _ / _ _ _ _"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    minDate={new Date()}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="available"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          {listingExist?.msg !== undefined &&
          listingExist?.msg !== null &&
          listingExist?.msg !== "" ? (
            <div className="col-xs-12">
              <p className="error">
                <i className="fi-warning"></i> {listingExist?.msg}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="cmnBtn text-center">
          <NavLink to="/listing" className="webBtn backBtn">
            Back
          </NavLink>
          {
            listing_row?.id > 0 ?
          (watchAllFields?.available===undefined || watchAllFields?.available===null || watchAllFields?.available==='') ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                trigger();
              }}
            >
              Next
            </button>
          ) 
          :
          <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => changeStep(step + 1)}
            >
              Next
            </button>
          : 
          !isValid ?
          <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                trigger();
              }}
            >
              Next
            </button>
            :
          listingExist?.status === 1 ||
            listing_row !== null ||
            listing_row?.id > 0 ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => changeStep(step + 1)}
              disabled={listing_row?.id > 0 && (watchAllFields?.available===undefined || watchAllFields?.available===null || watchAllFields?.available==='') ? true : !isValid ? true : false}
            >
              Next
            </button>
          ) : (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => handleListingExist()}
              disabled={
                !isValid || listingExist?.loading === true ? true : false
              }
            >
              Next{" "}
              {listingExist?.loading === true ? (
                <i className="spinner"></i>
              ) : (
                ""
              )}
            </button>
          )}
        </div>
      </fieldset>
    </>
  );
}
