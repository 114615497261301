import { format_amount, format_date } from "../../../../helpers/api"

export const ServiceTable = ({ service_payment_history }) => {
    return (
        <table>
            <thead>
                <tr>
                    <th>Date </th>
                    <th>Type</th>
                    <th className="text-right">Amount</th>
                </tr>
            </thead>
            <tbody>
                {
                    service_payment_history?.length > 0 ?
                        service_payment_history?.map((service_payment_history_row, index) => {
                            return (
                                <tr key={index}>
                                    <td><span className="mini-show-cell">Date</span>{format_date(service_payment_history_row?.created_at)}</td>
                                    <td><span className="mini-show-cell">Type</span>{service_payment_history_row?.description}</td>
                                    <td className="text-right service-align-right">
                                        <span className="mini-show-cell">Amount</span>
                                        {
                                          service_payment_history_row?.processing_fee ?  
                                        <div className="amount_flex">
                                            <div class="p_help">
                                                <div class="info flex">
                                                    <span class="total_lease_amount">(-{format_amount(service_payment_history_row?.processing_fee)}<strong> ?</strong>)
                                                    </span>
                                                    <div class="infoIn ckEditor">
                                                        <p>The amount of {format_amount(service_payment_history_row?.processing_fee)} was an additional cost to cover the processing fees for this transaction.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {format_amount(service_payment_history_row?.amount)}
                                        </div>
                                        :
                                        <>{format_amount(service_payment_history_row?.amount)}</>
                        }
                                        
                                    </td>
                                </tr>
                            )
                        })

                        :
                        <tr>
                            <td colSpan={3}>
                                <div className="alert alert-danger">No transactions found.</div>
                            </td>
                        </tr>
                }
            </tbody>
        </table>
    )
}
