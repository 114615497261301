import React from "react";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import {
  getSelectedValue,
  replaceSpaceWith20,
} from "../../../../../helpers/api";
import { NavLink } from "react-router-dom";

export default function PropertyStep2({
  step,
  changeStep,
  branches,
  floorPlans,
  property_row,
  handleBranchChange,
  countries,
  states,
  register,
  errors,
  control,
  isFormProcessing,
  watchAllFields,
  checkAddressAvailability,
  addressAvailable,
  showPopup,
  addressLoading,
  isValid,
  trigger,
}) {
  //
  return (
    <>
      <fieldset>
        <div className="form_row row flex">
          <div className="col-xs-6">
            <h6>Select Branch</h6>
            <div className="form_blk">
              <Controller
                {...register("branch", { required: "Required" })}
                name="branch"
                control={control}
                // onChange={(e) => handleBranchChange(e)}
                defaultValue={
                  property_row != undefined &&
                  property_row != null &&
                  property_row !== ""
                    ? property_row.branch
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={branches}
                      onChange={(val) => {
                        onChange(val.value);
                        handleBranchChange(val.value);
                      }}
                      label="Select Branch"
                      defaultValue={branches.filter(function (option) {
                        return option.value === value;
                      })}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="branch"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-6">
            <h6>Floor Plan</h6>
            <div className="form_blk">
              <select
                name="floor_plan"
                className="txtBox"
                id=""
                {...register("floor_plan", { required: "Required" })}
              >
                {floorPlans != null && floorPlans.length > 0 ? (
                  <>
                    <option value="">Select Floor Plan for Branch</option>
                    {floorPlans?.map((floorPlan, index) => {
                      return (
                        <>
                          <option
                            value={floorPlan.value}
                            key={index}
                            selected={
                              property_row?.floor_plan === floorPlan.value
                                ? true
                                : false
                            }
                          >
                            {floorPlan.label}
                          </option>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <option value="">No floor plans found</option>
                )}
              </select>
              <ErrorMessage
                errors={errors}
                name="floor_plan"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-6">
            <h6>Address Line 1</h6>
            <div className="form_blk">
              <input
                type="text"
                name="address_line1"
                id="address_line1"
                className="txtBox"
                {...register("address_line1", { required: "Required" })}
                defaultValue={property_row?.address_line1}
                disabled={
                  property_row !== undefined &&
                  property_row !== "" &&
                  property_row !== null
                    ? true
                    : false
                }
              />
              <ErrorMessage
                errors={errors}
                name="address_line1"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-6">
            <h6>Address Line 2</h6>
            <div className="form_blk">
              <input
                type="text"
                name="address_line2"
                id="address_line2"
                className="txtBox"
                {...register("address_line2")}
                defaultValue={
                  property_row?.address_line2 !== null &&
                  property_row?.address_line2 !== "" &&
                  property_row?.address_line2 !== "null"
                    ? property_row?.address_line2
                    : ""
                }
                disabled={
                  property_row !== undefined &&
                  property_row !== "" &&
                  property_row !== null
                    ? true
                    : false
                }
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>City</h6>
            <div className="form_blk">
              <input
                type="text"
                name="city"
                id="city"
                className="txtBox"
                {...register("city", { required: "Required" })}
                defaultValue={property_row?.city}
                disabled={
                  property_row !== undefined &&
                  property_row !== "" &&
                  property_row !== null
                    ? true
                    : false
                }
              />
              <ErrorMessage
                errors={errors}
                name="city"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>State</h6>
            <div className="form_blk">
              <Controller
                {...register("state", { required: "Required" })}
                name="state"
                control={control}
                defaultValue={
                  property_row != undefined &&
                  property_row != null &&
                  property_row !== ""
                    ? property_row.state
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={states}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      isDisabled={
                        property_row !== undefined &&
                        property_row !== "" &&
                        property_row !== null
                          ? true
                          : false
                      }
                      label="Select State"
                      defaultValue={states.filter(function (option) {
                        return option.value === value;
                      })}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="state"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>ZIP Code</h6>
            <div className="form_blk">
              <input
                type="text"
                name="zip_code"
                id="zip_code"
                className="txtBox"
                {...register("zip_code", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid value",
                  },
                  required: "Required",
                  minLength: {
                    value: 5,
                    message: "Zip code should be  equal to 5 digits",
                  },
                  maxLength: {
                    value: 5,
                    message: "Zip code should be  equal to 5 digits",
                  },
                })}
                defaultValue={property_row?.zip_code}
                disabled={
                  property_row !== undefined &&
                  property_row !== "" &&
                  property_row !== null
                    ? true
                    : false
                }
              />
              <ErrorMessage
                errors={errors}
                name="zip_code"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
        </div>
        <div className="small_flex_form">
          <div className="address_show flex">
            {addressAvailable !== null &&
            addressAvailable?.address !== null &&
            addressAvailable?.status === 1 ? (
              <p>{addressAvailable?.address}</p>
            ) : property_row?.formatted_address !== "" &&
              property_row?.formatted_address !== null &&
              property_row?.formatted_address !== undefined ? (
              <p>{property_row?.formatted_address}</p>
            ) : addressAvailable !== null &&
              addressAvailable?.address !== null &&
              addressAvailable?.status === 0 ? (
              <p className="error">
                <i className="fi-warning"></i> {addressAvailable?.msg}
              </p>
            ) : (
              <p></p>
            )}
            {/* <div className="bTn">
              {addressAvailable !== null &&
              addressAvailable?.address !== null &&
              addressAvailable?.status === 1 ? (
                <div className="text-success addressMsg">
                  <i className="fi-check-alt"></i> {addressAvailable?.msg}
                </div>
              ) : addressAvailable !== null &&
                addressAvailable?.address != null &&
                addressAvailable?.status === 0 ? (
                <div
                  className="text-danger addressMsg"
                  onClick={() => showPopup()}
                >
                  <i className="fa-exclamation-circle fa"></i> Address is
                  occupied. Use different address
                </div>
              ) : (
                ""
              )}
            </div> */}

            <div className="bTn">
            {
              addressAvailable?.address !== null &&
              addressAvailable?.status === 1 ? (
                <div className="text-success addressMsg">
                  <i className="fi-check-alt"></i> {addressAvailable?.msg}
                </div>
              )
              :
              ""
            }
              {addressAvailable !== null &&
              addressAvailable?.address !== null &&
              addressAvailable?.status === 1 &&
              addressAvailable?.latitude !== "" &&
              addressAvailable?.longitude !== "" ? (
                addressAvailable === null &&
                addressAvailable?.address === null &&
                watchAllFields?.address_line1 === "" &&
                watchAllFields?.city === "" &&
                watchAllFields?.state === "" &&
                watchAllFields?.zip_code === "" ? (
                  <div className="webBtn popBtn emptyBtn" disabled="true">
                    Fill the address first
                  </div>
                ) : (
                  <a
                    href="#!"
                    className="webBtn popBtn"
                    data-popup="address-not-match"
                    onClick={() => checkAddressAvailability(watchAllFields)}
                    disabled={addressAvailable?.loading === true ? true : false}
                  >
                    CHECK ADDRESS AVAILABILITY{" "}
                    {(addressAvailable?.loading === true) === true ? (
                      <i className="spinner"></i>
                    ) : (
                      ""
                    )}
                  </a>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {addressAvailable !== null &&
        addressAvailable?.address !== null &&
        addressAvailable?.status === 1 &&
        addressAvailable?.latitude !== "" &&
        addressAvailable?.longitude !== "" ? (
          <>
            {/* <GoogleMaps latitude={addressAvailable?.latitude} longitude={addressAvailable?.longitude} address={addressAvailable?.address} /> */}
            <div className="gMap">
              <iframe
                src={
                  "https://www.google.com/maps?q=" +
                  replaceSpaceWith20(addressAvailable?.address) +
                  "&output=embed"
                }
                width="100%"
                height="400"
                style={{ border: "0" }}
                loading="lazy"
              ></iframe>
            </div>
          </>
        ) : property_row?.formatted_address !== "" &&
          property_row?.formatted_address !== null &&
          property_row?.formatted_address !== undefined ? (
          <div className="gMap">
            <iframe
              src={
                "https://www.google.com/maps?q=" +
                replaceSpaceWith20(property_row?.formatted_address) +
                "&output=embed"
              }
              width="100%"
              height="400"
              style={{ border: "0" }}
              loading="lazy"
            ></iframe>
          </div>
        ) : (
          ""
        )}
        <div className="cmnBtn text-center">
          {/* {property_row !== undefined &&
          property_row !== null &&
          property_row !== "" ? (
            <NavLink to="/properties" className="webBtn backBtn">
              Back
            </NavLink>
          ) : (
            <button
              type="button"
              className="webBtn backBtn"
              onClick={() => changeStep(step - 1)}
            >
              Previous
            </button>
          )} */}
          <NavLink to="/properties" className="webBtn backBtn">
            Back
          </NavLink>
          <div className="address_show flex">
            
            {property_row !== undefined &&
          property_row !== null &&
          property_row !== "" &&
          watchAllFields?.branch !== "" &&
          watchAllFields?.floor_plan !== "" ? (
            <button
              type="submit"
              className="webBtn colorBtn"
              disabled={isFormProcessing === true ? "disabled" : ""}
            >
              {isFormProcessing === true ? (
                <i className="spinner"></i>
              ) : (
                "Submit"
              )}
            </button>
          ) : property_row !== undefined &&
            property_row !== null &&
            property_row !== "" ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                trigger();
              }}
            >
              Submit
            </button>
          ) : !isValid ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                trigger();
              }}
            >
              Check Address Availability
            </button>
          ) : addressAvailable?.address !== null &&
            addressAvailable?.status === 1 &&
            addressAvailable?.address !== "" ? (
            <button
              type="submit"
              className="webBtn colorBtn"
              disabled={isFormProcessing === true ? "disabled" : ""}
            >
              {isFormProcessing === true ? (
                <i className="spinner"></i>
              ) : (
                "Submit"
              )}
            </button>
          ) : addressAvailable?.address != null &&
            addressAvailable?.status === 0 ? (
            // <button
            //   type="button"
            //   className="webBtn colorBtn nextBtn"
            //   onClick={() => {
            //     showPopup();
            //   }}
            // >
            //   Check Address Availability
            // </button>
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => checkAddressAvailability(watchAllFields)}
              disabled={
                (addressAvailable?.loading === true) === true ? true : false
              }
            >
              Check Address Availability{" "}
              {(addressAvailable?.loading === true) === true ? (
                <i className="spinner"></i>
              ) : (
                ""
              )}
            </button>
          ) : property_row !== "" &&
            property_row !== undefined &&
            property_row !== null &&
            property_row?.id !== "" ? (
            <button
              type="submit"
              className="webBtn colorBtn"
              disabled={isFormProcessing === true ? "disabled" : ""}
            >
              {isFormProcessing === true ? (
                <i className="spinner"></i>
              ) : (
                "Submit"
              )}
            </button>
          ) : (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => checkAddressAvailability(watchAllFields)}
              disabled={
                (addressAvailable?.loading === true) === true ? true : false
              }
            >
              Check Address Availability{" "}
              {(addressAvailable?.loading === true) === true ? (
                <i className="spinner"></i>
              ) : (
                ""
              )}
            </button>
          )}
          </div>
          
        </div>
      </fieldset>
    </>
  );
}
