import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useState ,useEffect} from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  calculatePercentageOfAmount,
  format_amount,
  postData,
} from "../../../../helpers/api";
import toast, { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";
import AddNewCreditCard from "./add-new-credit-card";
import AddNewAchBankAccount from "./add-new-ach-bank-account";
import { NavLink } from "react-router-dom";

export default function PaymentForm({
  isPaymentFormProcessing,
  handleSavePayment,
  lease_row,
  mem_payment_methods,
  member,
  mem_payment_methods_ach,
  siteSettings,
}) {
  const [paymentMethod, setPaymentMethod] = useState("credit-card");
  const [addNewCreditCard, setAddNewCreditCard] = useState(false);
  const [paymentConfirmPopup, setPaymentConfirmPopup] = useState(false);
  const [addNewAchBankAccount, setAddNewAchBankAccount] = useState(false);
  const choosePaymentMethod = (value) => {
    setPaymentMethod(value);
  };
  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  useEffect(() => {
      if (mem_payment_methods_ach?.length > 0) {
        setValue("ach_account", mem_payment_methods_ach[0]?.encoded_id);
      }
      if (mem_payment_methods?.length > 0) {
        setValue("payment_cards", mem_payment_methods[0]?.encoded_id);
      }
      trigger()
    }, [mem_payment_methods]);
  const watchAllFields = watch();
  const onSubmit = async (frmData) => {
    if (paymentMethod === "ach-bank") {
      let newData = {
        ...frmData,
        payment_method: "ach",
        lease_id: lease_row?.id,
      };
      handleSavePayment(newData);
    } else if (paymentMethod === "credit-card") {
      let newData = {
        ...frmData,
        payment_method: paymentMethod,
        lease_id: lease_row?.id,
      };
      handleSavePayment(newData);
    } else {
      toast.error("Payment method is invalid!");
      return;
    }
  };

  const validateAmount = (value) => {
    const amount = parseFloat(value);
    // if (amount < 5 || amount > parseFloat(lease_row?.available_balance)) {
    if (amount < 5) {
      return "Amount should be greater than $5";
    } else if (amount > 5) {
      if (paymentMethod === "credit-card") {
        let stripe_fee = calculatePercentageOfAmount(
          parseFloat(amount),
          siteSettings?.site_stripe_fee
        );

        let site_stripe_flat_fee = siteSettings?.site_stripe_flat_fee;
        let final_stripe_processing_fee =
          parseFloat(stripe_fee) + parseFloat(site_stripe_flat_fee);

        let final_ach_processing_fee = 0;
        if (
          parseFloat(amount) >= parseFloat(siteSettings?.site_ach_threshold)
        ) {
          let ach_fee = calculatePercentageOfAmount(
            amount,
            siteSettings?.site_ach_fee_max
          );
          final_ach_processing_fee =
            parseFloat(ach_fee) +
            parseFloat(siteSettings?.site_ach_flat_fee_max);
        } else if (
          parseFloat(amount) < parseFloat(siteSettings?.site_ach_threshold)
        ) {
          let ach_fee = calculatePercentageOfAmount(
            amount,
            siteSettings?.site_ach_fee
          );
          final_ach_processing_fee =
            parseFloat(ach_fee) + parseFloat(siteSettings?.site_ach_flat_fee);
        }
        final_ach_processing_fee = parseFloat(final_ach_processing_fee);
        let final_processing_fee =
          final_ach_processing_fee + final_stripe_processing_fee;
        console.log(final_stripe_processing_fee, final_ach_processing_fee);
        setValue("processing_fee", final_processing_fee.toFixed(2));
        let total_fee =
          parseFloat(amount) +
          final_processing_fee;
        // let total_fee =
        //   parseFloat(amount) +
        //   final_processing_fee +
        //   siteSettings?.site_processing_fee;
        setValue("landlord_processing_fee", siteSettings?.site_processing_fee);
        setValue("total_fee", total_fee.toFixed(2));
      } else if (paymentMethod === "ach-bank") {
        let final_ach_processing_fee = 0;
        let final_landlord_processing_fee = 0;
        if (
          parseFloat(amount) >= parseFloat(siteSettings?.site_ach_threshold)
        ) {
          let ach_fee = calculatePercentageOfAmount(
            amount,
            siteSettings?.site_ach_fee_max
          );
          final_landlord_processing_fee =
            parseFloat(ach_fee) +
            parseFloat(siteSettings?.site_ach_flat_fee_max);
        } else if (
          parseFloat(amount) < parseFloat(siteSettings?.site_ach_threshold)
        ) {
          let ach_fee = calculatePercentageOfAmount(
            amount,
            siteSettings?.site_ach_fee
          );
          final_landlord_processing_fee =
            parseFloat(ach_fee) + parseFloat(siteSettings?.site_ach_flat_fee);
        }
        final_landlord_processing_fee = parseFloat(final_landlord_processing_fee) * 2;
        final_landlord_processing_fee = parseFloat(final_landlord_processing_fee) + parseFloat(siteSettings?.site_processing_fee);
        setValue("processing_fee", final_ach_processing_fee.toFixed(2));
        setValue("landlord_processing_fee", final_landlord_processing_fee.toFixed(2));
        let total_fee =
          parseFloat(amount) +
          final_ach_processing_fee;
        // let total_fee =
        //   parseFloat(amount) +
        //   final_ach_processing_fee +
        //   siteSettings?.site_processing_fee;
        setValue("total_fee", total_fee.toFixed(2));
      }
    }
    return true;
  };
  console.log(watchAllFields)
  return (
    <div className="form_row row justify_center_flex chk">
      <Toaster position="bottom-right" />
      <ToastContainer />
      <div className="col-xs-12">
        <form method="post" onSubmit={handleSubmit(onSubmit)}>
          <div className="form_row row justify_center_flex chk">
            <div className="col-xs-12">
              <div className="creditCard">
                <div className="inner">
                  <h6>Pay Balance</h6>
                  <div className="enterRsBlk">
                    <strong>$</strong>
                    <input
                      type="text"
                      name="amount"
                      placeholder=""
                      className="txtBox"
                      {...register("amount", {
                        required: "Amount is required",
                        validate: validateAmount,
                        pattern: {
                          value: /^\d+(\.\d{1,2})?$/, // Allows whole numbers or numbers with up to two decimal places
                          message: "Invalid value",
                        },
                        min: {
                          value: 5, // Minimum value is now 5
                          message: "Amount should be greater than $5",
                        },
                      })}
                    />
                    {/* <span>.00</span> */}
                  </div>

                  <ErrorMessage
                    errors={errors}
                    name="amount"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
                <div className="gap_line_divide"></div>
                <div className="flex choose_payment_method_method">
                  <div className="lblBtn">
                    <label
                      for="card"
                      className={
                        paymentMethod === "credit-card"
                          ? "cardLbl active"
                          : "cardLbl"
                      }
                      onClick={() => choosePaymentMethod("credit-card")}
                    >
                      Credit Card
                    </label>
                  </div>
                  <div className="lblBtn">
                    <label
                      for="bank"
                      className={
                        paymentMethod === "ach-bank"
                          ? "bankLbl active"
                          : "bankLbl"
                      }
                      onClick={() => choosePaymentMethod("ach-bank")}
                    >
                      Bank Account
                    </label>
                  </div>
                </div>
                {/* <div className="flex flex-2 headCredit paypalSecBar">
                                    <div className="inner flex lease_payment_credit_flex">
                                        <div className="lblBtn text-left">
                                            <input type="radio" id="card1" tabindex="1" name="card" checked={paymentMethod === 'credit-card' ? true : false} onChange={() => choosePaymentMethod('credit-card')} />
                                            <label for="card2">Credit Card</label>
                                        </div>


                                    </div>
                                </div> */}
                {paymentMethod === "credit-card" ? (
                  <div className="flex flex-2 cardSec">
                    <div className="inner">
                      {/* <div className="text-center small_card_txt">
                        <p>
                          <small>
                            Please choose a stored card for payment. Your
                            selection is needed to proceed with the transaction.
                          </small>
                        </p>
                      </div> */}

                      <select
                        className="txtBox"
                        {...register("payment_cards", {
                          required: "Required",
                        })}
                      >
                        {mem_payment_methods?.length > 0 ? (
                          mem_payment_methods?.map(
                            (mem_payment_method, index) => {
                              if (index == 0) {
                                return (
                                  <option
                                    value={mem_payment_method?.encoded_id}
                                    key={index}
                                    selected
                                  >
                                    ************
                                    {mem_payment_method?.card_number}
                                  </option>
                                );
                              } else {
                                return (
                                  <option
                                    value={mem_payment_method?.encoded_id}
                                    key={index}
                                  >
                                    ************
                                    {mem_payment_method?.card_number}
                                  </option>
                                );
                              }
                            }
                          )
                        ) : (
                          <option value="">No card found!</option>
                        )}
                      </select>
                      <ErrorMessage
                        errors={errors}
                        name="payment_cards"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>
                    <div className=" bTn text-center addNewPaymentMethodBtn">
                      <a
                        href="#!"
                        className="webBtn smBtn"
                        onClick={() => setAddNewCreditCard(true)}
                      >
                        <i className="fa fa-plus"></i> Credit Card
                      </a>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* <div className="flex flex-2 headCredit cardSecBar">
                                    <div className="inner flex lease_payment_credit_flex">
                                        <div className="lblBtn text-left">
                                            <input type="radio" id="card1" tabindex="1" name="card" checked={paymentMethod === 'ach-bank' ? true : false} onChange={() => choosePaymentMethod('ach-bank')} />
                                            <label for="card1">ACH Bank Account</label>
                                        </div>


                                    </div>
                                </div> */}
                {paymentMethod === "ach-bank" ? (
                  <div className="flex flex-2 cardSec">
                    <div className="inner">
                      <select
                        className="txtBox"
                        {...register("ach_account", {
                          required: "Required",
                        })}
                      >
                        {mem_payment_methods_ach?.length > 0 ? (
                          mem_payment_methods_ach?.map(
                            (mem_payment_method_ach, index) => {
                              if (index == 0) {
                                return (
                                  <option
                                    value={mem_payment_method_ach?.encoded_id}
                                    key={index}
                                    selected
                                  >
                                    ************
                                    {mem_payment_method_ach?.card_number}
                                  </option>
                                );
                              } else {
                                return (
                                  <option
                                    value={mem_payment_method_ach?.encoded_id}
                                    key={index}
                                  >
                                    ************
                                    {mem_payment_method_ach?.card_number}
                                  </option>
                                );
                              }
                            }
                          )
                        ) : (
                          <option value="" selected>
                            No account found!
                          </option>
                        )}
                      </select>
                      <ErrorMessage
                        errors={errors}
                        name="ach_account"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>
                    <div className=" bTn text-center addNewPaymentMethodBtn">
                      <a
                        href="#!"
                        className="webBtn smBtn"
                        onClick={() => setAddNewAchBankAccount(true)}
                      >
                        <i className="fa fa-plus"></i> Bank Account
                      </a>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {paymentMethod === "ach-bank" ? (
                  <ul class="total_count_price_listing full_wide_price_listing cell_full_wide_lst_cell">
                    <li>
                      <em>Balance</em>
                      <em>
                        {watchAllFields?.amount !== null &&
                        watchAllFields?.amount !== undefined &&
                        watchAllFields?.amount !== ""
                          ? format_amount(watchAllFields?.amount)
                          : "$0.00"}
                      </em>
                    </li>
                    {/* <li>
                      <em>Loftus Fee</em>
                      <em>
                        {format_amount(siteSettings?.site_processing_fee)}
                      </em>
                    </li> */}
                    <li>
                      <em>Processing Fee</em>
                      <em>
                        Waived
                      </em>
                    </li>
                    {/* <li>
                      <em>Processing Fee</em>
                      <div className="lblBtn processing_help_icon">
                        <div class="info lease_month_info">
                          <strong></strong>
                          <div class="infoIn ckEditor">
                            <p>
                              A flat fee of{" "}
                              {format_amount(siteSettings?.site_ach_flat_fee)}{" "}
                              will be applied to transactions less than or equal
                              to{" "}
                              {format_amount(
                                parseFloat(siteSettings?.site_ach_threshold)
                              )}
                              . Transactions greater than{" "}
                              {format_amount(
                                parseFloat(siteSettings?.site_ach_threshold)
                              )}{" "}
                              will incur a fee of {siteSettings?.site_ach_fee}%.
                            </p>
                          </div>
                        </div>
                      </div>
                      <em>
                        {watchAllFields?.processing_fee !== null &&
                        watchAllFields?.processing_fee !== undefined &&
                        watchAllFields?.processing_fee !== ""
                          ? format_amount(watchAllFields?.processing_fee)
                          : "$0.00"}
                      </em>
                    </li> */}
                    <li>
                      <strong>Total Due</strong>
                      <strong>
                        {format_amount(
                          watchAllFields?.amount !== null &&
                            watchAllFields?.amount !== undefined &&
                            watchAllFields?.amount !== ""
                            ? watchAllFields?.total_fee
                            : 0
                        )}
                      </strong>
                    </li>
                  </ul>
                ) : paymentMethod === "credit-card" ? (
                  <ul class="total_count_price_listing full_wide_price_listing cell_full_wide_lst_cell">
                    <li>
                      <em>Balance</em>
                      <em>
                        {watchAllFields?.amount !== null &&
                        watchAllFields?.amount !== undefined &&
                        watchAllFields?.amount !== ""
                          ? format_amount(watchAllFields?.amount)
                          : "$0.00"}
                      </em>
                    </li>
                    {/* <li>
                      <em>Loftus Fee</em>
                      <em>
                        {format_amount(siteSettings?.site_processing_fee)}
                      </em>
                    </li> */}
                    <li>
                      <em>Processing Fee</em>
                      <div className="lblBtn processing_help_icon">
                        <div class="info lease_month_info">
                          <strong></strong>
                          <div class="infoIn ckEditor">
                            <p>
                              Transactions will incur{" "}
                              {siteSettings?.site_stripe_fee}% fee in addition
                              to a flat fee of{" "}
                              {format_amount(
                                siteSettings?.site_stripe_flat_fee
                              )}
                              .
                            </p>
                          </div>
                        </div>
                      </div>
                      <em>
                        {watchAllFields?.processing_fee !== null &&
                        watchAllFields?.processing_fee !== undefined &&
                        watchAllFields?.processing_fee !== ""
                          ? format_amount(watchAllFields?.processing_fee)
                          : "$0.00"}
                      </em>
                    </li>
                    <li>
                      <strong>Total Due</strong>
                      <strong>
                        {format_amount(
                          watchAllFields?.amount !== null &&
                            watchAllFields?.amount !== undefined &&
                            watchAllFields?.amount !== ""
                            ? watchAllFields?.total_fee
                            : 0
                        )}
                      </strong>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </div>
              <div className="bTn  offer_action_btn">
                <ul className="text-right">
                  <li>
                    <img src="/images/dashboard/card1.svg" alt="" />
                  </li>
                  <li>
                    <img src="/images/dashboard/card2.svg" alt="" />
                  </li>
                  <li>
                    <img src="/images/dashboard/card3.svg" alt="" />
                  </li>
                </ul>
                {!isValid ? (
                  <button
                    type="button"
                    className="webBtn colorBtn"
                    onClick={() => {
                      trigger();
                    }}
                  >
                    Pay Now
                  </button>
                ) : (
                  <button
                    type="button"
                    className="webBtn colorBtn"
                    onClick={() => setPaymentConfirmPopup(true)}
                  >
                    Pay Now{" "}
                  </button>
                )}
              </div>
            </div>
            {paymentConfirmPopup ? (
              <div index className="popup small_popup">
                <div className="tableDv">
                  <div className="tableCell">
                    <ToastContainer />
                    <div className="_inner">
                      <div
                        className="crosBtn"
                        onClick={() => setPaymentConfirmPopup(false)}
                      ></div>
                      <h4>Please confirm your payment submission.</h4>
                      <div className="bTn text-center">
                        <NavLink
                          to="#!"
                          className="webBtn"
                          onClick={() => setPaymentConfirmPopup(false)}
                        >
                          Cancel
                        </NavLink>
                        <button
                          type="submit"
                          className="webBtn colorBtn"
                          disabled={isPaymentFormProcessing ? "disabled" : ""}
                        >
                          Confirm{" "}
                          {isPaymentFormProcessing ? (
                            <i className="spinner"></i>
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </form>
        {addNewCreditCard === true ? (
          <AddNewCreditCard
            setAddNewCreditCard={setAddNewCreditCard}
            siteSettings={siteSettings}
          />
        ) : (
          ""
        )}
        {addNewAchBankAccount === true ? (
          <AddNewAchBankAccount
            setAddNewAchBankAccount={setAddNewAchBankAccount}
            member={member}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
